import moment from 'moment';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AppstoreOutlined,
  PaperClipOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import jwtDecode from 'jwt-decode';
import { addTab } from '~/store/modules/tabs/actions';

import logo from '~/assets/images/logo.png';
import { BIDashboardContext } from '~/context/BIDashboardContext';
import * as API from '~/domain/api';
import { menuEnum } from '~/domain/enum/menu.enum';
import * as M from '~/domain/model';
import { getAccessToken, removeTokens } from '~/helpers/util/storage';
import { actions, selectors } from '~/store/modules';
import { fetchBiDashboard } from '~/store/modules/bidashboards/apiCalls';
import {
  getBiDashboardPagination,
  getBiDashboards
} from '~/store/modules/bidashboards/selectors';
import {
  FeatureFlags,
  useFeatureFlagStore
} from '~/zustand/featureFlag/useFeatureFlagStore';
import DefaultBiDashboard from '../pages/DefaultBiDashboard';
import OtherPlatforms from '../pages/OtherPlatforms';
import ServiceRequests from '../pages/ServiceRequests';
import { menus, MenuType } from './menus';
import * as S from './styles';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const permissions = useSelector(selectors.ui.auth.getPermissions);
  const profile = useSelector(selectors.ui.auth.getProfile);
  const accessToken = getAccessToken();

  const { formFilter } = useContext(BIDashboardContext);
  const biDashboards = useSelector(getBiDashboards);
  const biDashboardPagination = useSelector(getBiDashboardPagination);

  const [collapsed, setCollapsed] = useState<boolean>(!!isMobile);

  function onCollapse(collapsedItem: boolean) {
    setCollapsed(collapsedItem);
  }
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    const biDashboardParams: M.FindBiDashboardParams = {
      name: formFilter?.getFieldValue('name'),
      status: formFilter?.getFieldValue('status'),
      ...biDashboardPagination
    };
    dispatch(fetchBiDashboard(biDashboardParams));
  }, []);

  useEffect(() => {
    dispatch(actions.ui.auth.fetchPermissions());
    const accessData: API.Profile = jwtDecode(accessToken);
    dispatch(
      actions.ui.auth.setProfile({
        veiling_claim_type_email: accessData.veiling_claim_type_email,
        veiling_claim_type_identifier: accessData.veiling_claim_type_identifier,
        veiling_claim_type_name: accessData.veiling_claim_type_name,
        veiling_claim_type_role: accessData.veiling_claim_type_role
      })
    );
  }, []);

  useEffect(() => {
    const { exp }: any = jwtDecode(accessToken);
    const expireDate = moment(exp * 1000);
    if (expireDate) {
      const now = moment().format('DD/MM/YYYY HH:mm:ss');
      const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(now, 'DD/MM/YYYY HH:mm:ss')
      );
      const timeLeftInMin = Math.floor(timeLeftInMs / 60000);
      setTimeLeft(timeLeftInMin);
    }
  }, [accessToken]);

  useEffect(() => {
    const timer = setInterval(() => setMinutesRemaining(), 60000);
    if (timeLeft <= 0) {
      removeTokens();
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const setMinutesRemaining = () => {
    const { exp }: any = jwtDecode(getAccessToken());
    const expireDate = moment(exp * 1000);
    if (expireDate) {
      const now = moment().format('DD/MM/YYYY HH:mm:ss');
      const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(now, 'DD/MM/YYYY HH:mm:ss')
      );
      const timeLeftInMin = Math.floor(timeLeftInMs / 60000);
      setTimeLeft(timeLeftInMin);
    }
  };

  const alertUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const isFeatureFlagEnabled = useFeatureFlagStore(
    state => state.isFeatureFlagEnabled
  );

  const isDeskManagerServiceRequestsEnabled = isFeatureFlagEnabled(
    FeatureFlags.DeskManagerServiceRequests
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  const otherPlatforms: MenuType = {
    key: 'otherPlatforms',
    name: 'menu.otherPlatforms',
    title: 'menu.otherPlatforms',
    component: () => <OtherPlatforms />
  };

  const serviceRequests: MenuType = {
    key: 'serviceRequests',
    name: 'Abertura de Chamado',
    title: 'Abertura de Chamado',
    component: () => <ServiceRequests />
  };

  const renderSubMenus = useCallback(
    (menuItems: MenuType[]) => {
      const items = menuItems.map(item => {
        if (
          permissions?.findIndex(
            p => p.permissionName === item.permissionName
          ) !== -1
        ) {
          return (
            <S.MenuItem
              key={item.key}
              onClick={() => {
                dispatch(addTab(item));
              }}
            >
              {t(item.title)}
            </S.MenuItem>
          );
        }
        if (item.permissionName === 'MOCK') {
          return (
            <S.MenuItem key={item.key} onClick={() => dispatch(addTab(item))}>
              [MOCK] {t(item.title)}
            </S.MenuItem>
          );
        }
        return null;
      });
      return items;
    },
    [dispatch, permissions, t]
  );

  const renderMenu = useMemo(() => {
    const mainMenu: any = menus;
    const index = mainMenu.findIndex((i: any) => i.key === 'indicatorsPanel');
    if (index && mainMenu[index]) {
      if (biDashboards.length) {
        biDashboards.map((dashboard: any, dashIndex: number) => {
          const menuItemIndex = mainMenu[index].MenuItems.findIndex(
            (i: any) => i.name === dashboard.name
          );
          if (!mainMenu[index].MenuItems[menuItemIndex]) {
            mainMenu[index].MenuItems.push({
              key: dashboard.name.replaceAll(' ', ''),
              name: dashboard.name,
              component: () => <DefaultBiDashboard index={dashIndex} />,
              title: dashboard.name,
              permissionName: `Menu.IndicatorsPanel.${dashboard.name.replaceAll(
                ' ',
                ''
              )}`
            });
          }
        });
      }
    }

    const items: any = mainMenu.map((menu: MenuType) => {
      if (
        permissions?.findIndex(
          p => p.permissionName === menu.permissionName
        ) !== -1
      ) {
        if (!menu.MenuItems) {
          return (
            <S.MenuItem
              key={menu.key}
              icon={menu.icon}
              onClick={() => dispatch(addTab(menu))}
            >
              {t(menu.title)}
            </S.MenuItem>
          );
        }
        return (
          <S.SubMenu key={menu.key} icon={menu.icon} title={t(menu.title)}>
            {renderSubMenus(menu.MenuItems)}
          </S.SubMenu>
        );
      }
    });

    return items;
  }, [biDashboards, dispatch, permissions, renderSubMenus, t]);

  const logout = useCallback(() => {
    removeTokens();
  }, []);
  return (
    <>
      <S.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={256}
        className={isMobile ? 'isMobile' : ''}
      >
        <div>
          <S.Logo className={collapsed ? 'collapsed' : ''}>
            <img loading="lazy" src={logo} alt="" />
          </S.Logo>

          <S.MenuContainer defaultSelectedKeys={['1']} mode="inline">
            <S.MenuGroup
              key="navigation"
              className={collapsed || isMobile ? 'isMobile' : ''}
            >
              {renderMenu}
            </S.MenuGroup>
          </S.MenuContainer>
        </div>

        <div>
          <S.ProfileContent
            className={`${collapsed ? 'collapsed' : ''} ${
              isMobile ? 'isMobile' : ''
            }`}
          >
            <div>
              <S.MenuContainer mode="inline">
                <S.MenuGroup
                  key="navigation"
                  className={collapsed || isMobile ? 'isMobile' : ''}
                >
                  <S.MenuItem
                    icon={<AppstoreOutlined />}
                    key={1}
                    onClick={() => {
                      window.location.href = String(
                        `${process.env.REACT_APP_SINGLE_SIGNON}apps`
                      );
                    }}
                  >
                    {t('menu.apps')}
                  </S.MenuItem>
                  <S.MenuItem
                    icon={<PaperClipOutlined />}
                    key={2}
                    onClick={() => dispatch(addTab(otherPlatforms))}
                  >
                    {t('menu.otherPlatforms')}
                  </S.MenuItem>
                  <S.SubMenu
                    key={2.1}
                    icon={<UserOutlined />}
                    title={profile.veiling_claim_type_name}
                  >
                    <S.MenuItem
                      key={2.11}
                      onClick={() =>
                        isDeskManagerServiceRequestsEnabled
                          ? dispatch(addTab(serviceRequests))
                          : window.open(menuEnum.LINKSUPPORT)
                      }
                    >
                      Abertura de Chamados
                    </S.MenuItem>

                    <S.MenuItem
                      key={2.12}
                      onClick={() => {
                        logout();
                      }}
                    >
                      {t('menu.logoff')}
                    </S.MenuItem>
                  </S.SubMenu>
                </S.MenuGroup>
              </S.MenuContainer>
            </div>
          </S.ProfileContent>

          {!collapsed && (
            <S.MessageExpiration>
              Sua sessão vai expirar em {timeLeft} min
            </S.MessageExpiration>
          )}
        </div>
      </S.Sider>
    </>
  );
};
export default memo(Menu);
