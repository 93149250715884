import { Button, Col, Divider, Row } from 'antd';
import GitInfo from 'react-git-info/macro';
import { useState } from 'react';
import moment from 'moment';
import { ModalBody, ModalDefault, ModalHeader } from '~/components/Globals';

export default function GitInfoModal() {
  const [visible, setVisible] = useState<boolean>(
    process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'qa'
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const gitInfo = GitInfo();
  const Footer = () => (
    <Row gutter={24}>
      <Col xs={24} lg={24}>
        <Button type="primary" style={{ width: '100%' }} onClick={handleCancel}>
          Fechar
        </Button>
      </Col>
    </Row>
  );

  return (
    <ModalDefault
      title=""
      width={800}
      visible={visible}
      onCancel={handleCancel}
      footer={<Footer />}
      style={{ borderRadius: 8 }}
    >
      <ModalHeader>
        <span>Informações do sistema</span>
      </ModalHeader>
      <ModalBody>
        <h2>Ambiente</h2>
        <h4>{`${process.env.REACT_APP_ENV}`.toUpperCase()}</h4>
        <Divider />
        {gitInfo?.branch ? (
          <>
            <h2>Branch</h2>
            <h4>{gitInfo.branch}</h4>
            <Divider />
          </>
        ) : null}
        {gitInfo?.tags?.length ? (
          <>
            <h2>Tags</h2>
            <h4>{gitInfo?.tags.join(' | ')}</h4>
            <Divider />
          </>
        ) : null}
        {gitInfo?.commit ? (
          <>
            <h2>Último Commit</h2>
            <h4>message: {gitInfo.commit.message}</h4>
            <h4>
              date: {moment(gitInfo.commit.date).format('DD/MM/YYYY HH:mm:ss')}
            </h4>
            <h4>hash: {gitInfo.commit.hash}</h4>
            <h4>shortHash: {gitInfo.commit.shortHash}</h4>
          </>
        ) : null}
      </ModalBody>
    </ModalDefault>
  );
}
