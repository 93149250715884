import { create } from 'zustand';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface DoneState {
  dones: ExpeditionDone[];
  donePagination: TablePagination;
  isLoadingDones: boolean;
  handleDonePagination: (newPagination: TablePagination) => void;
  addDones: (donesValues: ExpeditionDone[]) => void;
  setIsLoadingDones: () => void;
}

const useDoneStore = create<DoneState>()(set => ({
  dones: [],
  donePagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingDones: false,
  handleDonePagination: newPagination => set({ donePagination: newPagination }),
  addDones: donesValues => set({ dones: donesValues }),
  setIsLoadingDones: () => {
    set(state => ({ isLoadingDones: !state.isLoadingDones }));
  }
}));

export default useDoneStore;
