import { FormInstance, Table, Tooltip } from 'antd';
import moment from 'moment';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import { formatBr } from '~/utils/date';
import useNonConformityGeralStore from '~/zustand/rfidReports/expedition/nonConformityGeral-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableExpeditionNonConformityGeral(props: Props) {
  const {
    nonConformities,
    nonConformityPagination,
    handleNonConformityPagination,
    isLoadingNonConformities
  } = useNonConformityGeralStore();

  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Lista de Produtores Aceitos em Inconformidades
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={nonConformities}
          loading={isLoadingNonConformities}
          pagination={{
            pageSize: nonConformityPagination.pageSize,
            current: nonConformityPagination.current,
            showSizeChanger: true,
            total: nonConformityPagination.total,
            onChange(page, pageSize) {
              handleNonConformityPagination({
                ...nonConformityPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, expedition: ExpeditionDone) =>
              expedition.status === 2 ? (
                <Tooltip>
                  <StatusCircle color="#52C41A" />
                </Tooltip>
              ) : (
                <Tooltip>
                  <StatusCircle color="#FA541C" />
                </Tooltip>
              )
            }
            title="Status"
          />
          <Table.Column
            dataIndex="expeditionDate"
            title="Data Expedição"
            render={(expeditionDate: string) =>
              moment(expeditionDate).format(formatBr)
            }
          />
          <Table.Column
            dataIndex="receiptDate"
            title="Data Recebimento"
            render={(receiptDate: string) =>
              moment(receiptDate).format(formatBr)
            }
          />
          <Table.Column dataIndex="producerId" title="Produtor" />
          <Table.Column dataIndex="area" title="Área" />
          <Table.Column dataIndex="productCode" title="Serial do Carrinho" />
          <Table.Column dataIndex="numberGFP" title="Total GFP" />
          <Table.Column dataIndex="numberMC" title="MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}
