import { create } from 'zustand';
import Done from '~/@core/domain/model/rfidReports/Done';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface DoneState {
  dones: Done[];
  donePagination: TablePagination;
  isLoadingDone: boolean;
  handleDonePagination: (newPagination: TablePagination) => void;
  addDones: (donesValues: Done[]) => void;
  setIsLoadingDone: () => void;
}

const useDoneStore = create<DoneState>()(set => ({
  dones: [],
  donePagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingDone: false,
  handleDonePagination: newPagination => set({ donePagination: newPagination }),
  addDones: donesValues => set({ dones: donesValues }),
  setIsLoadingDone: () => {
    set(state => ({ isLoadingDone: !state.isLoadingDone }));
  }
}));

export default useDoneStore;
