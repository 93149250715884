/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CopyOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, FormInstance, Input, Select, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetProductPacking from '~/@core/application/useCases/inputs/product/getProductPacking';
import FilterPackings from '~/@core/domain/filters/packing/FilterPacking';
import Packing from '~/@core/domain/model/packing/Packing';
import ResolvedStockQuantities from '~/@core/domain/model/stock/ResolvedStockQuantities';
import Stocks from '~/@core/domain/model/stock/Stocks';
import { QualityContainer } from '~/components/atoms/QualityContainer';
import { DividerFrame, GroupTitleInformation } from '~/components/Globals';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { Order } from '~/domain/model';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import { SpanLabel, StyledText } from '../TableListOrders/style';
import { FrameStyled, StyledTable } from './style';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

interface Props {
  form: FormInstance<any>;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return (
    <>
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child, childIndex) => {
          if ((child as React.ReactElement).key === 'sort') {
            return (
              <React.Fragment key={childIndex}>
                {React.cloneElement(child as React.ReactElement, {
                  children: (
                    <MenuOutlined
                      ref={setActivatorNodeRef}
                      style={{ touchAction: 'none', cursor: 'move' }}
                      {...listeners}
                    />
                  )
                })}
              </React.Fragment>
            );
          }
          return child;
        })}
      </tr>
    </>
  );
};

export default function TableConfirmKlokGFP(props: Props) {
  const { t } = useTranslation();
  const { form } = props;

  const [packings, setPackings] = useState<Packing[]>([]);

  const { handlePromiseUseCase, activeAlert, siteIdSelected } =
    useContext(globalContext);

  const { qualityObservations } = useContext(GFPContext);

  const {
    stocks,
    setStocks,
    stocksSelected,
    setStocksSelected,
    duplicateStock,
    removeStock
  } = useGfpStore();

  const [currentPage, setCurrentPage] = useState(1);

  const [pageSizes, setPageSizes] = useState(10);

  const handleChangeStockSelected = (
    valueParam: any,
    property: string,
    indexTable: number,
    stock: Stocks
  ) => {
    let value: string | number = 0;
    if (typeof valueParam === 'string') {
      value = valueParam;
    }
    if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
      value = valueParam;
    }

    const stockMaped = stocks.map((stockLoop: Stocks) => {
      if (stockLoop.stockKey === stock.stockKey) {
        return {
          ...stockLoop,
          [property]: value,
          changed: true
        };
      }
      return stockLoop;
    });

    setStocks(stockMaped);

    const selectedStock = stocksSelected.findIndex(
      x => x.stockKey === stock.stockKey
    );

    if (selectedStock >= 0) {
      const stockSelectedMapped = stocksSelected.map((stockLoop: Stocks) => {
        if (stockLoop.stockKey === stock.stockKey) {
          return {
            ...stockLoop,
            [property]: value,
            changed: true
          };
        }

        return stockLoop;
      });
      setStocksSelected(stockSelectedMapped);
    }
  };

  const handleDeleteStock = useCallback(
    (stockKey: number) => {
      removeStock(stockKey);
    },
    [removeStock]
  );

  const fetchPackings = useCallback(
    (filter: FilterPackings) => {
      setPackings([]);
      const getProductPacking = new GetProductPacking();
      handlePromiseUseCase<Packing[], AxiosError>(
        getProductPacking.execute(filter),
        packingsOut => {
          setPackings(packingsOut);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, handlePromiseUseCase]
  );

  const columns: any = [
    {
      title: '',
      align: 'center',
      key: 'sort',
      width: 50
    },
    {
      title: 'Lotes',
      align: 'center',
      key: 'key',
      width: 50,
      render: (value: any, record: Order, index: number) => {
        const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
        const adjustedIndex =
          (index + (currentPage - 1) * pageSizes) % letters.length;
        return letters[adjustedIndex];
      }
    },
    {
      key: 'productDescription',
      title: 'Descrição do produto',
      dataIndex: 'productDescription',
      render: (value: any, record: Stocks) => {
        return (
          <GroupTitleInformation>
            <Tooltip title={record.productDescription}>
              <StyledText>
                {record.productDescription?.slice(0, 25) +
                  (record.productDescription?.length > 20 ? '...' : '')}
              </StyledText>
            </Tooltip>
            <SpanLabel>{record.productCode}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'qualityDescription',
      title: 'Qld.',
      dataIndex: 'qualityDescription',
      align: 'center',
      width: 50,
      render: (value: any) => {
        return <QualityContainer quality={value}>{value}</QualityContainer>;
      }
    },
    {
      key: 'packingCode',
      title: 'Emb.',
      dataIndex: 'packingCode',
      width: 80,
      render: (_: any, record: Stocks, index: number) => {
        return (
          <Select
            placeholder="Selecione..."
            style={{ width: '75px' }}
            filterOption
            showSearch
            dropdownStyle={{ minWidth: '300px' }}
            defaultValue={`${record.packingCode} - ${record.packingDescription}`}
            onChange={value => {
              const packingId = packings.find(
                packing => packing.id === Number(value)
              )?.id;

              handleChangeStockSelected(packingId, 'packingId', index, record);
            }}
            onClick={() => {
              const filterProduct = {
                siteId: siteIdSelected,
                productId: record.productId
              };
              fetchPackings(filterProduct);
            }}
          >
            {packings &&
              packings.map(packing => (
                <Select.Option key={packing.code} value={packing.id}>
                  {packing.code} - {packing.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'quantityPacking',
      title: 'QE',
      align: 'center',
      width: 50,
      render: (quantityPacking: number, record: Stocks, index: number) => {
        return (
          <Input
            width="100%"
            key={quantityPacking || ''}
            value={record.quantityPacking}
            onChange={e => {
              const value = Number(e.target.value);
              handleChangeStockSelected(
                value,
                'quantityPacking',
                index,
                record
              );
            }}
            style={{ width: '50px', textAlign: 'center' }}
          />
        );
      }
    },
    {
      key: 'quantityPerPacking',
      title: 'QpE',
      align: 'center',
      width: 50,
      render: (quantityPerPacking: number, record: Stocks, index: number) => {
        return (
          <Input
            width="100%"
            key={quantityPerPacking || ''}
            value={record.quantityPerPacking}
            onChange={e => {
              const value = Number(e.target.value);
              handleChangeStockSelected(
                value,
                'quantityPerPacking',
                index,
                record
              );
            }}
            style={{ width: '50px', textAlign: 'center' }}
          />
        );
      }
    },
    {
      key: 'observationId1',
      title: 'Obs 1.',
      dataIndex: 'observation',
      width: 60,
      render: (_: any, record: Stocks, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '200px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{ maxWidth: 60, width: 60 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeStockSelected(
                value?.value ?? '',
                'observationId1',
                index,
                record
              );
            }}
            value={record.observationId1 ? [record.observationId1] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observationId2',
      title: 'Obs 2.',
      dataIndex: 'observation',
      width: 60,
      render: (_: any, record: Stocks, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '220px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{ maxWidth: 60, width: 60 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeStockSelected(
                value?.value ?? '',
                'observationId2',
                index,
                record
              );
            }}
            value={record.observationId2 ? [record.observationId2] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 30,
      render: (_text: string, record: Stocks, index: number) => {
        return (
          <span>
            <Button
              type="default"
              icon={<CopyOutlined />}
              onClick={() =>
                stocksSelected.length <= 5 &&
                duplicateStock(record.stockKey as number)
              }
              style={{ marginRight: '5px' }}
            />
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteStock(record.stockKey as number)}
            />
          </span>
        );
      }
    }
  ];

  if (!stocks.length || stocks.every(x => !x.isVolOnly)) {
    columns.splice(3, 0, {
      key: 'remainingQuantity',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Quantidade</StyledText>
            <StyledText>em Estoque</StyledText>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'remainingQuantity',
      align: 'center',
      width: 100,
      render: (resolvedQuantities: ResolvedStockQuantities, record: Stocks) => {
        return (
          record.resolvedQuantities?.remainingKlokHoard ??
          record.remainingQuantity
        );
      }
    });
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = stocksSelected.findIndex(
        item => item.stockKey === active.id
      );
      const newIndex = stocksSelected.findIndex(
        item => item.stockKey === over?.id
      );
      const newItems = arrayMove(stocksSelected, oldIndex, newIndex);
      setStocksSelected(newItems);
    }
  };

  return (
    <FrameStyled defaultActiveKey="table" ghost accordion>
      <FrameStyled.Panel
        header={<DividerFrame orientation="left">Estoque</DividerFrame>}
        key="table"
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={stocks.map(item => item.stockKey as number)}
            strategy={verticalListSortingStrategy}
          >
            <StyledTable
              components={{ body: { row: Row } }}
              rowKey="stockKey"
              columns={columns}
              dataSource={stocksSelected}
              pagination={false}
              rowClassName={(record, index) => {
                const groupNumber = Math.floor(index / 6);
                return groupNumber % 2 === 0 ? '' : 'gray-background';
              }}
            />
          </SortableContext>
        </DndContext>
      </FrameStyled.Panel>
    </FrameStyled>
  );
}
