import { AxiosResponse } from 'axios';
import AutoCategory from '~/domain/model/ServiceRequests/AutoCategory';
import Category from '~/domain/model/ServiceRequests/Category';
import SubCategory from '~/domain/model/ServiceRequests/SubCategory';
import { apiSession } from '~/services/api';

export const checkIfDeskManagerUserIsValid = (email: string) => {
  return apiSession.get<void, AxiosResponse<boolean>>(
    `desk-manager/check-if-user-exists`,
    { params: { email } }
  );
};

export type IFetchParams = {
  Pesquisa: string;
  Ativo: string;
};

export const getServiceRequestsCategories = (params: IFetchParams) => {
  return apiSession.get<void, AxiosResponse<Category[]>>(
    `desk-manager/categories`,
    {
      params
    }
  );
};

export const getServiceRequestsSubCategories = (params: IFetchParams) => {
  return apiSession.get<void, AxiosResponse<SubCategory[]>>(
    `desk-manager/sub-categories`,
    {
      params
    }
  );
};

export const getServiceRequestsAutoCategories = (params: IFetchParams) => {
  return apiSession.get<void, AxiosResponse<AutoCategory[]>>(
    `desk-manager/auto-categories`,
    {
      params
    }
  );
};

export type ISendAttachmentFileParams = {
  Base64: string;
  Type: string;
  FileName: string;
  FileSize: string;
};

export type AttachmentFileResponse = {
  Chave: string;
};

export const sendAttachmentFile = (params: ISendAttachmentFileParams) => {
  return apiSession.post<void, AxiosResponse<AttachmentFileResponse>>(
    `desk-manager/attachment-ticket`,
    params
  );
};

export type ICreateServiceRequestParams = {
  Email: string;
  Solicitacao: string;
  Assunto: string;
  AutoCategoria: string;
  Descricao: string;
  // TCampoExtra: IExtraFields;
};

export type IExtraFields = {
  Campo8756: string;
};

export type IServiceRequestResponse = string | { erro: string };

export const createServiceRequest = (params: ICreateServiceRequestParams) => {
  return apiSession.post<void, AxiosResponse<IServiceRequestResponse>>(
    `desk-manager/create-ticket`,
    params
  );
};
