import { Button as ButtonAntd, ButtonProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const TextAreaDescription = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 150px !important;
`;

export const Button = styled(ButtonAntd)<ButtonProps>`
  width: 100%;
`;
