import { FormInstance, Table, Tooltip } from 'antd';
import moment from 'moment';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import { formatBr } from '~/utils/date';
import useTransitStore from '~/zustand/rfidReports/expedition/transit-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableExpeditionTransit(props: Props) {
  const {
    transits,
    transitPagination,
    handleTransitPagination,
    isLoadingTransits
  } = useTransitStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Expedições em Trânsito para Veiling
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={transits}
          loading={isLoadingTransits}
          pagination={{
            pageSize: transitPagination.pageSize,
            current: transitPagination.current,
            showSizeChanger: true,
            total: transitPagination.total,
            onChange(page, pageSize) {
              handleTransitPagination({
                ...transitPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: expeditionTransits => (
              <Table
                dataSource={expeditionTransits.carts}
                expandable={{
                  expandedRowRender: expeditionTransitsCarts => (
                    <Table
                      dataSource={expeditionTransitsCarts.acessories}
                      bordered
                    >
                      <Table.Column
                        dataIndex="productCode"
                        title="Código Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />
                      <Table.Column
                        dataIndex="results"
                        title="Resultado/Esperado"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column dataIndex="codeGFP" title="GFP" />
                <Table.Column dataIndex="sequency" title="Sequência" />
                <Table.Column
                  dataIndex="numberMC"
                  title="Total MC Realizado/Esperado"
                />
              </Table>
            )
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, expedition: ExpeditionDone) =>
              expedition.status !== 2 ? (
                <Tooltip>
                  <StatusCircle color="#BBBBBD" />
                </Tooltip>
              ) : (
                <Tooltip>
                  <StatusCircle color="#52C41A" />
                </Tooltip>
              )
            }
            title="Status"
          />
          <Table.Column
            dataIndex="expeditionDate"
            title="Data Expedição"
            render={(expeditionDate: string) =>
              moment(expeditionDate).format(formatBr)
            }
          />
          <Table.Column dataIndex="area" title="Area" />
          <Table.Column dataIndex="productCode" title="Serial do Carrinho" />
          <Table.Column dataIndex="numberGFP" title="Total GFP" />
          <Table.Column dataIndex="numberMC" title="MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}
