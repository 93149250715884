import { FormInstance, Table } from 'antd';
import moment from 'moment';
import { useMemo } from 'react';
import Items from '~/@core/domain/model/rfidReports/Items';
import { DividerFrame, Frame } from '~/components/Globals';
import { formatBr } from '~/utils/date';
import useDoneStore from '~/zustand/rfidReports/inventory/done-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableInventoryDone(props: Props) {
  const { dones, donePagination, handleDonePagination, isLoadingDone } =
    useDoneStore();
  const { form } = props;
  const focusControlledColumns = useMemo(() => {
    return ['description'];
  }, []);

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">Inventários Realizados</DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={dones}
          loading={isLoadingDone}
          pagination={{
            pageSize: donePagination.pageSize,
            current: donePagination.current,
            showSizeChanger: true,
            total: donePagination.total,
            onChange(page, pageSize) {
              handleDonePagination({
                ...donePagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: inventoryDones => (
              <Table
                dataSource={inventoryDones.items}
                expandable={{
                  expandedRowRender: inventoryDonesAcessory => (
                    <Table dataSource={inventoryDonesAcessory.carts} bordered>
                      <Table.Column
                        dataIndex="productCode"
                        title="Serial do Carrinho"
                      />
                      <Table.Column dataIndex="area" title="Área" />
                      <Table.Column
                        dataIndex="status"
                        title="Status Divergência"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column dataIndex="productCode" title="Código Material" />
                <Table.Column dataIndex="type" title="Tipo Material" />
                <Table.Column
                  dataIndex="areas"
                  key="areas"
                  title="Áreas"
                  align="center"
                  render={(_, inventoryDonesItems: Items, index: number) => {
                    return inventoryDonesItems.areas.map(item => (
                      <span style={{ marginLeft: '40px' }}>
                        {' '}
                        <tr>{item.name}</tr>
                        <tr>{`${item.quantity} / ${item.expected}`}</tr>
                      </span>
                    ));
                  }}
                />

                <Table.Column dataIndex="number" title="Resultado/Esperado" />
                <Table.Column dataIndex="balance" title="Saldo CVH" />
              </Table>
            )
          }}
        >
          <Table.Column
            dataIndex="modificationDate"
            title="Data Inventário"
            render={(modificationDate: string) =>
              moment(modificationDate).format(formatBr)
            }
          />

          <Table.Column dataIndex="type" title="Tipo Material" />
          <Table.Column
            dataIndex="numberCart"
            title="Carrinho Resultado/Esperado"
          />
          <Table.Column dataIndex="numberMC" title="MC Resultado/Esperado" />
          <Table.Column dataIndex="stock" title="Estoque CVH" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}
