import { Col, Form, Select, Upload, message } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useShallow } from 'zustand/react/shallow';
import DefaultRow from '~/components/bosons/DefaultRow';
import AutoCategory from '~/domain/model/ServiceRequests/AutoCategory';
import Category from '~/domain/model/ServiceRequests/Category';
import SubCategory from '~/domain/model/ServiceRequests/SubCategory';
import { Site } from '~/interface';
import { selectors } from '~/store/modules';
import useServiceRequestsStore, {
  IFormFilter
} from '~/zustand/serviceRequests/useServiceRequestsStore';
import * as S from './ServiceRequestsFilters.styled';

export default function ServiceRequestsFilters() {
  const [formFilter] = useForm<IFormFilter>();
  const profile = useSelector(selectors.ui.auth.getProfile);

  const {
    setFormInstance,
    filters,
    validateEmailAndLoadFilters,
    isFiltersSuccessful,
    getSubCategories,
    getAutoCategories,
    sendAttachmentFile,
    createServiceRequest
  } = useServiceRequestsStore(
    useShallow(state => ({
      setFormInstance: state.setFormInstance,
      filters: state.filters,
      validateEmailAndLoadFilters: state.validateEmailAndLoadFilters,
      isFiltersSuccessful: state.isFiltersSuccessful,
      getSubCategories: state.getSubCategories,
      getAutoCategories: state.getAutoCategories,
      sendAttachmentFile: state.sendAttachmentFile,
      createServiceRequest: state.createServiceRequest
    }))
  );

  useEffect(() => {
    const instance = {
      validateFields: formFilter.validateFields,
      getFieldValue: formFilter.getFieldValue,
      getFieldsValue: formFilter.getFieldsValue,
      setFieldValue: formFilter.setFieldValue,
      setFieldsValue: formFilter.setFieldsValue,
      resetFields: formFilter.resetFields
    } as Partial<FormInstance<IFormFilter>>;

    setFormInstance(instance);
  }, [formFilter, setFormInstance]);

  useEffect(() => {
    validateEmailAndLoadFilters(profile.veiling_claim_type_email);
  }, [validateEmailAndLoadFilters]);

  const onSubmit = async () => {
    try {
      await formFilter.validateFields();
      const response = await createServiceRequest();

      if (response && typeof response === 'object' && 'erro' in response) {
        const errorMessage = response.erro;
        message.error(errorMessage);
        return;
      }
      message.success(
        `Solicitação criada com sucesso! Número do chamado: ${response}`
      );
    } catch (error) {
      message.error(
        'Alguns campos de filtro são obrigatórios. Por favor, preencha-os e tente novamente.'
      );
    }
  };

  const handleCategoryOnClear = () => {
    formFilter.setFieldsValue({
      categoryId: undefined
    });
  };

  const handleCategoryOnChange = () => {
    formFilter.setFieldsValue({
      subCategoryId: undefined,
      autoCategoryId: undefined
    });

    getSubCategories();
    getAutoCategories();
  };

  const handleSubgroupOnClear = () => {
    formFilter.setFieldsValue({
      subCategoryId: undefined
    });
  };

  const handleSubgroupOnChange = () => {
    formFilter.setFieldsValue({
      autoCategoryId: undefined
    });

    getAutoCategories();
  };

  const handleTypeOnClear = () => {
    formFilter.setFieldsValue({
      autoCategoryId: undefined
    });
  };

  return (
    <Form
      layout="vertical"
      form={formFilter}
      initialValues={filters.formFilter}
    >
      <DefaultRow>
        <Col lg={24}>
          <Form.Item
            label="Chave / Razão Social / Nome Fantasia"
            name="siteId"
            rules={[
              {
                required: true,
                message: 'Selecione corretamente uma chave de produtor'
              }
            ]}
          >
            <Select
              placeholder="Selecione uma chave"
              showSearch
              allowClear
              showArrow
              loading={filters.sites.isLoading}
              disabled={filters.sites.isLoading}
              optionFilterProp="children"
              optionLabelProp="label"
            >
              {filters.sites.data.map((item: Site) => (
                <Select.Option
                  key={item.account}
                  value={item.id}
                  label={`${item.account} - ${item.corporateName} (${item.tradingName})`}
                  title={item.corporateName}
                >
                  {`${item.account} - ${item.corporateName} (${item.tradingName})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Categoria"
            name="categoryId"
            rules={[
              {
                required: true,
                message: 'Selecione corretamente uma categoria'
              }
            ]}
          >
            <Select
              placeholder="Selecione uma categoria"
              showSearch
              showArrow
              allowClear
              loading={filters.categories.isLoading}
              disabled={filters.categories.isLoading}
              optionFilterProp="children"
              optionLabelProp="label"
              onClear={handleCategoryOnClear}
              onChange={handleCategoryOnChange}
            >
              {filters.categories.data.map((item: Category) => (
                <Select.Option
                  key={item.account}
                  value={item.account}
                  label={item.name}
                  title={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Sub Categoria"
            name="subCategoryId"
            rules={[
              {
                required: true,
                message: 'Selecione corretamente uma sub categoria'
              }
            ]}
          >
            <Select
              placeholder="Selecione uma sub categoria"
              showSearch
              showArrow
              allowClear
              loading={filters.subCategories.isLoading}
              disabled={filters.subCategories.isLoading}
              optionFilterProp="children"
              optionLabelProp="label"
              onClear={handleSubgroupOnClear}
              onChange={handleSubgroupOnChange}
            >
              {filters.subCategories.data.map((item: SubCategory) => (
                <Select.Option
                  key={item.account}
                  value={item.account}
                  label={item.name}
                  title={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            label="Assunto"
            name="autoCategoryId"
            rules={[
              {
                required: true,
                message: 'Selecione corretamente um assunto de solicitação'
              }
            ]}
          >
            <Select
              placeholder="Selecione um assunto de solicitação"
              showSearch
              showArrow
              allowClear
              loading={filters.autoCategories.isLoading}
              disabled={filters.autoCategories.isLoading}
              optionFilterProp="children"
              optionLabelProp="label"
              onClear={handleTypeOnClear}
            >
              {filters.autoCategories.data.map((item: AutoCategory) => (
                <Select.Option
                  key={item.account}
                  value={item.account}
                  label={item.name}
                  title={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[{ required: true, message: 'Descreva seu incidente' }]}
          >
            <S.TextAreaDescription />
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item>
            <Upload.Dragger
              name="attachment"
              fileList={filters.attachments.data}
              beforeUpload={(file: RcFile) => {
                const isLt4M = file.size / 1024 / 1024 < 4;
                if (!isLt4M) {
                  message.error('O Arquivo deve ser menor que 4MB!');
                }
                return isLt4M;
              }}
              onChange={info => sendAttachmentFile(info)}
              customRequest={(options: any) => {
                const { onSuccess } = options;
                onSuccess('Ok');
              }}
            >
              Anexar Imagem
            </Upload.Dragger>
          </Form.Item>
        </Col>
        <Col lg={4}>
          <S.Button
            disabled={!isFiltersSuccessful || !filters.email.isValid}
            onClick={onSubmit}
            type="primary"
            htmlType="submit"
            aria-label="search"
            size="large"
          >
            Enviar
          </S.Button>
        </Col>
      </DefaultRow>
    </Form>
  );
}
