import { FormInstance, Table, Tooltip } from 'antd';
import moment from 'moment';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import { formatBr } from '~/utils/date';
import useVisionStore from '~/zustand/rfidReports/expedition/vision-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableExpeditionVision(props: Props) {
  const {
    visions,
    visionPagination,
    handleVisionPagination,
    isLoadingVisions
  } = useVisionStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Lista de Produtores com Produtos em Trânsito para Veiling
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={visions}
          loading={isLoadingVisions}
          pagination={{
            pageSize: visionPagination.pageSize,
            current: visionPagination.current,
            showSizeChanger: true,
            total: visionPagination.total,
            onChange(page, pageSize) {
              handleVisionPagination({
                ...visionPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, expedition: ExpeditionDone) => {
              switch (expedition.status) {
                case 2:
                  return (
                    <Tooltip>
                      <StatusCircle color="#52C41A" />
                    </Tooltip>
                  );
                  break;
                case 1:
                  return (
                    <Tooltip>
                      <StatusCircle color="#BBBBBD" />
                    </Tooltip>
                  );
                  break;
                default:
                  return (
                    <Tooltip>
                      <StatusCircle color="#FA541C" />
                    </Tooltip>
                  );
                  break;
              }
            }}
            title="Status"
          />
          <Table.Column
            dataIndex="expeditionDate"
            title="Data Expedição"
            render={(expeditionDate: string) =>
              moment(expeditionDate).format(formatBr)
            }
          />
          <Table.Column dataIndex="producerId" title="Produtor" />
          <Table.Column dataIndex="area" title="Área" />
          <Table.Column dataIndex="productCode" title="Serial do Carrinho" />
          <Table.Column dataIndex="numberGFP" title="Total GFP" />
          <Table.Column dataIndex="numberMC" title="MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}
