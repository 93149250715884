import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import CreateGfp from '~/@core/application/useCases/gfp/createGfp';
import FormFilterCreateGfpModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterCreateKlokGfp';
import {
  ModalBody,
  ModalDefault,
  ModalFooter,
  ModalHeader,
  TabContainer
} from '~/components/Globals';
import { ConfirmModal } from '~/components/Modals/ConfirmModal';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { alertRequest } from '~/store/modules/alert/actions';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import TableAddKlokGFP from '../../Tables/TableAddKlokGFP';
import TableConfirmKlokGFP from '../../Tables/TableConfirmKlokGFP';
import FormAddKlokGFP from './FormAddKlokGFP';
import { TabContainerAddKlokGFP, TabNumber } from './style';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export default function ModalAddKlokGFP(props: Props) {
  const { t } = useTranslation();
  const { isModalVisible, setIsModalVisible } = props;
  const { siteIdSelected, activeAlert, handlePromiseUseCase } =
    useContext(globalContext);
  const { setStocks, stocksSelected, setStocksSelected } = useGfpStore();

  const { isPageLoading, setIsPageLoading } = useContext(GFPContext);

  const [activeTabKey, setActiveTabKey] = useState<string>('1');

  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [modal, context] = Modal.useModal();
  const [form] = Form.useForm();

  const [modalConfirmationIsVisible, setModalConfirmationIsVisible] =
    useState<boolean>(false);

  let totalPacking = 0;
  totalPacking = stocksSelected.reduce(
    (acc, elem) => acc + Number(elem.quantityPacking),
    0
  );

  useEffect(() => {
    form.setFieldValue('gfpItems', stocksSelected);
  }, [form, stocksSelected]);

  const tabRegister = useMemo(() => {
    return (
      <Space>
        {activeTabKey === '1' ? (
          <TabNumber selected>1</TabNumber>
        ) : (
          <CheckCircleOutlined
            type="primary"
            style={{ fontSize: 30 }}
            onClick={() => setActiveTabKey('1')}
          />
        )}
        {t('gfp.modal.addGfp.create')}
      </Space>
    );
  }, [activeTabKey, t]);

  const tabResume = useMemo(() => {
    return (
      <Space>
        <TabNumber selected={activeTabKey === '2'}>2</TabNumber>
        {t('gfp.modal.addGfp.resume')}
      </Space>
    );
  }, [activeTabKey, t]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setStocks([]);
    setStocksSelected([]);
    setActiveTabKey('1');
    setModalConfirmationIsVisible(false);
    setIsModalVisible(false);
    form.resetFields([
      'emissionDate',
      'pickupLocation',
      'partitionQuantity',
      'multiple',
      'transporterId',
      'observation',
      'gfpItems',
      'search'
    ]);
  }, [form, setIsModalVisible, setStocks, setStocksSelected]);

  const currentDate = moment(new Date());

  const commercialCurrentDate: Moment = (() => {
    const dayOfWeek = currentDate.day();

    if (dayOfWeek === 3 || dayOfWeek === 6) {
      return currentDate.clone().add(2, 'days');
    }

    if (dayOfWeek === 5) {
      return currentDate.clone().add(3, 'days');
    }
    return currentDate.clone().add(1, 'day');
  })();

  const onSubmitForm = useCallback(
    (formValues: FormFilterCreateGfpModel) => {
      const filterKlokGfps = MapFormToFilter.toFilter(
        formValues,
        siteIdSelected
      );
      if (!isPageLoading) setIsPageLoading(true);
      const createKlokGfp = new CreateGfp();
      handlePromiseUseCase<AxiosResponse<void>, AxiosError>(
        createKlokGfp.execute(filterKlokGfps),
        () => {
          setIsPageLoading(false);
          activeAlert({
            message: 'GFP criada com sucesso!',
            type: 'success',
            timeout: 5000
          });
          handleCancel();
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [
      activeAlert,
      handleCancel,
      handlePromiseUseCase,
      isPageLoading,
      setIsPageLoading,
      siteIdSelected
    ]
  );

  useEffect(() => {
    setStocksSelected([]);
    setStocks([]);
  }, [isModalVisible, setStocks, setStocksSelected]);

  const areAllFieldsFilled = () => {
    return stocksSelected.every(
      stock => stock.quantityPacking && stock.quantityPerPacking
    );
  };

  const handleNextStep = async () => {
    if (areAllFieldsFilled()) {
      try {
        await form.validateFields();
        setActiveTabKey('2');
      } catch {
        alertRequest('Os campos obrigatórios devem ser preenchidos.');
      }
    } else {
      activeAlert({
        message: 'É preciso preencher os campos QE e QpE para avançar',
        type: 'error',
        timeout: 5000
      });
    }
  };

  const handleOnCancelModal = useCallback(() => {
    setModalConfirmationIsVisible(true);
  }, []);

  return (
    <>
      <ModalDefault
        title=""
        width={1300}
        open={isModalVisible}
        onCancel={handleOnCancelModal}
        destroyOnClose
      >
        <ModalHeader>
          <span>{t('gfp.modal.addGfp.title')}</span>
        </ModalHeader>
        {context}
        <ModalBody>
          <Form
            form={form}
            onFinish={onSubmitForm}
            initialValues={{
              emissionDate: currentDate,
              outDate: currentDate,
              commercialDate: commercialCurrentDate,
              pickupLocation: 'CVH'
            }}
          >
            <TabContainerAddKlokGFP
              centered
              activeKey={activeTabKey}
              style={{ padding: 8 }}
            >
              <TabContainer.TabPane tab={tabRegister} key="1">
                <FormAddKlokGFP form={form} />
                <TableAddKlokGFP form={form} />
                <Form.Item name="gfpItems" style={{ display: 'none' }} />
              </TabContainer.TabPane>
              <TabContainer.TabPane tab={tabResume} key="2">
                <FormAddKlokGFP form={form} />
                <TableConfirmKlokGFP form={form} />
              </TabContainer.TabPane>
            </TabContainerAddKlokGFP>
            <Col flex={1}>
              <Form.Item name="observation" label="Observação GFP:">
                <Input />
              </Form.Item>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Row justify="space-between" gutter={[0, 24]} align="middle">
            <Col>
              <p
                style={{
                  color: '#8c8c8c',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                Total de embalagens:
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    color: 'black'
                  }}
                >
                  {`${totalPacking}`}
                </span>
              </p>
            </Col>
            <Col
              style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}
            >
              {activeTabKey === '1' ? (
                <>
                  <Button
                    type="default"
                    onClick={() => setModalConfirmationIsVisible(true)}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleNextStep}
                    disabled={!(stocksSelected.length > 0)}
                  >
                    Continuar
                  </Button>
                </>
              ) : (
                <>
                  <Button type="default" onClick={() => setActiveTabKey('1')}>
                    Voltar
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => form.submit()}
                    disabled={!(stocksSelected.length > 0)}
                  >
                    Gerar GFPs
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </ModalDefault>
      <ConfirmModal
        confirmIcon={
          <ExclamationCircleOutlined
            style={{ color: '#F78E1E', fontSize: 25 }}
          />
        }
        confirmTitle="Cancelar"
        onOk={handleCancel}
        onCancel={() => setModalConfirmationIsVisible(false)}
        question="Tem certeza que deseja descartar este lançamento?"
        visible={modalConfirmationIsVisible}
        cancelText="Cancelar"
        okText="Sim, descartar"
      />
    </>
  );
}
