import { create } from 'zustand';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface VisionState {
  visions: ExpeditionDone[];
  visionPagination: TablePagination;
  isLoadingVisions: boolean;
  handleVisionPagination: (newPagination: TablePagination) => void;
  addVisions: (visionsValues: ExpeditionDone[]) => void;
  setIsLoadingVisions: () => void;
}

const useVisionStore = create<VisionState>()(set => ({
  visions: [],
  visionPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingVisions: false,
  handleVisionPagination: newPagination =>
    set({ visionPagination: newPagination }),
  addVisions: visionsValues => set({ visions: visionsValues }),
  setIsLoadingVisions: () => {
    set(state => ({
      isLoadingVisions: !state.isLoadingVisions
    }));
  }
}));

export default useVisionStore;
