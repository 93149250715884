// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-imports
import React from 'react';
import styled from 'styled-components';

export const Title = styled.h4`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  color: #000;
  margin-bottom: 4px;
`;

export const Subtitle = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #7c7c7c;
`;
