import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import GetNonConformitiesGeral from '~/@core/application/useCases/rfidReports/expedition/getNonConformitiesGeral';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';

import FormFilterDataModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterDataModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useNonConformityGeralStore from '~/zustand/rfidReports/expedition/nonConformityGeral-state';
import { SearchButtonDiv } from '../../Inventory/Dones/FormFilterInventoryDone.styled';

interface Props {
  form: FormInstance<FormFilterDataModel>;
}
export default function FormFilterExpeditionNonConformityGeral(props: Props) {
  const { form } = props;
  const {
    nonConformityPagination,
    addNonConformities,
    handleNonConformityPagination,
    setIsLoadingNonConformities
  } = useNonConformityGeralStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    form.setFieldsValue({
      dtaFilter: []
    });
  }, [form]);

  const onSubmitFilter = useCallback(
    (formValues: FormFilterDataModel) => {
      const filterNonConformity = MapFormToFilter.toFilter(formValues, {
        pageSize: nonConformityPagination.pageSize,
        pageNumber: nonConformityPagination.current
      });

      const getNonConformities = new GetNonConformitiesGeral();
      setIsLoadingNonConformities();

      handlePromiseUseCase<DefaultPaginated<ExpeditionDone[]>, AxiosError>(
        getNonConformities.execute(filterNonConformity),
        nonConformitiesOut => {
          addNonConformities(nonConformitiesOut.results);
          handleNonConformityPagination({
            ...nonConformityPagination,
            total: nonConformitiesOut.totalCount
          });
          setIsLoadingNonConformities();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingNonConformities();
        }
      );
    },
    [
      activeAlert,
      addNonConformities,
      handleNonConformityPagination,
      handlePromiseUseCase,
      nonConformityPagination,
      setIsLoadingNonConformities
    ]
  );
  return (
    <Form layout="vertical" form={form} onFinish={onSubmitFilter}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item name="dtaFilter" label="Data de Consulta: ">
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Início', 'Fim']}
            />
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {}}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
