import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, FormInstance, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GetNonConformities from '~/@core/application/useCases/rfidReports/expedition/getNonConformities';
import GetProducers from '~/@core/application/useCases/rfidReports/inventory/getProducers';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import FormFilterDataModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterDataModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useNonConformityStore from '~/zustand/rfidReports/expedition/nonConformity-state';
import useProducerStore from '~/zustand/rfidReports/inventory/producer-state';
import { SearchButtonDiv } from '../../Inventory/Dones/FormFilterInventoryDone.styled';

const { Option } = Select;

interface Props {
  form: FormInstance<FormFilterDataModel>;
}
export default function FormFilterExpeditionNonConformity(props: Props) {
  const { form } = props;
  const { t } = useTranslation();
  const {
    nonConformityPagination,
    addNonConformities,
    handleNonConformityPagination,
    setIsLoadingNonConformities
  } = useNonConformityStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    form.setFieldsValue({
      dtaFilter: []
    });
  }, [form]);

  const { producers, addProducers } = useProducerStore();

  useEffect(() => {
    const getProducers = new GetProducers();

    handlePromiseUseCase<Producer[], AxiosError>(
      getProducers.execute(),
      producerOut => {
        addProducers(producerOut);
      },
      error => {
        activeAlert({
          message: JSON.stringify(
            error.response?.data
              ? error.response?.data
              : 'Houve um erro inesperado!'
          ),
          type: 'error',
          timeout: 5000
        });
      }
    );
  }, []);

  const onSubmitFilterNonConformity = useCallback(
    (formValues: FormFilterDataModel) => {
      const filterNonConformity = MapFormToFilter.toFilter(formValues, {
        pageSize: nonConformityPagination.pageSize,
        pageNumber: nonConformityPagination.current
      });

      const getNonConformities = new GetNonConformities();
      setIsLoadingNonConformities();

      handlePromiseUseCase<DefaultPaginated<ExpeditionDone[]>, AxiosError>(
        getNonConformities.execute(filterNonConformity),
        nonConformitiesOut => {
          addNonConformities(nonConformitiesOut.results);
          handleNonConformityPagination({
            ...nonConformityPagination,
            total: nonConformitiesOut.totalCount
          });
          setIsLoadingNonConformities();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingNonConformities();
        }
      );
    },
    [
      activeAlert,
      addNonConformities,
      handleNonConformityPagination,
      handlePromiseUseCase,
      nonConformityPagination,
      setIsLoadingNonConformities
    ]
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSubmitFilterNonConformity}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item name="dtaFilter" label="Data de Consulta: ">
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Início', 'Fim']}
            />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            name="producerFilter"
            label="Sitio: "
            rules={[{ required: true }]}
          >
            <Select allowClear placeholder={t('select')}>
              {producers.map(producer => (
                <Option value={producer.account}>
                  {producer.account} - {producer.tradingName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
