/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CommentOutlined,
  DeleteOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Input, Select, Table, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetProductPacking from '~/@core/application/useCases/inputs/product/getProductPacking';
import FilterPackings from '~/@core/domain/filters/packing/FilterPacking';
import Order from '~/@core/domain/model/order/Order';
import Packing from '~/@core/domain/model/packing/Packing';
import { QualityContainer } from '~/components/atoms/QualityContainer';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { onlyNumber } from '~/helpers/util';
import { setPageSize } from '~/store/modules/productionPlan/actions';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import ObservationModal from '../../Modals/ObservationModal/ObservationModal';
import {
  GroupTitleInformation,
  SpanLabel,
  StyledText
} from '../TableListOrders/style';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function TableAssembleGFP() {
  const { t } = useTranslation();
  const { handlePromiseUseCase, activeAlert, siteIdSelected } =
    useContext(globalContext);
  const {
    orderSelected,
    setOrderSelected,
    qualityObservations,
    isMultiple,
    setIsMultiple,
    showObservationModal,
    setShowObservationModal
  } = useContext(GFPContext);

  const { handleChangeStocks } = useGfpStore();

  const [packings, setPackings] = useState<Packing[]>([]);

  const [currentOrderNumber, setCurrentOrderNumber] = useState<number | null>(
    null
  );

  const [, setTotalPacking] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [pageSizes, setPageSizes] = useState(10);

  const handleChangeGFP = useCallback(
    (valueParam: any, property: string, indexTable: number) => {
      let value: string | number = 0;

      if (typeof valueParam === 'string') {
        value = valueParam;
      }
      if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
        value = valueParam;
      }

      const newOrderTableChanged = orderSelected.map(
        (newOrderTable, indexMap) => {
          if (indexMap === indexTable) {
            const newQuantityPacking =
              property === 'quantityPacking'
                ? Math.min(
                    Number(value),
                    newOrderTable.recommendedQuantityPacking
                  )
                : newOrderTable.quantityPacking;

            const newQeRest =
              newQuantityPacking === newOrderTable.recommendedQuantityPacking
                ? 0
                : newOrderTable.recommendedQuantityPacking - newQuantityPacking;

            return {
              ...newOrderTable,
              [property]: Number(value),
              quantityQeRest: newQeRest,
              changed: true
            };
          }
          return newOrderTable;
        }
      );

      setOrderSelected(newOrderTableChanged);
    },
    [orderSelected, setOrderSelected]
  );

  const handleQeChange = useCallback(
    (valueParam: any, property: string, indexTable: number) => {
      const value = Number(valueParam);

      if (value <= 0 || (valueParam.startsWith('0') && valueParam.length > 1)) {
        return;
      }

      const newOrderTableChanged = orderSelected.map(
        (newOrderTable, indexMap) => {
          if (indexMap === indexTable) {
            const newQuantityPacking =
              property === 'quantityPacking'
                ? Math.min(value, newOrderTable.recommendedQuantityPacking)
                : newOrderTable.quantityPacking;

            const newQeRest =
              newQuantityPacking === newOrderTable.recommendedQuantityPacking
                ? 0
                : newOrderTable.recommendedQuantityPacking - newQuantityPacking;

            return {
              ...newOrderTable,
              [property]: newQuantityPacking,
              quantityQeRest: newQeRest,
              changed: true
            };
          }
          return newOrderTable;
        }
      );

      setOrderSelected(newOrderTableChanged);
    },
    [orderSelected, setOrderSelected]
  );
  const handlePackingChange = useCallback(
    (packingCode: string, indexTable: number, packingId: number) => {
      const newOrderTableChanged = orderSelected.map(
        (newOrderTable, indexMap) => {
          if (indexMap === indexTable) {
            return {
              ...newOrderTable,
              packingCode,
              packingId,
              changed: true
            };
          }
          return newOrderTable;
        }
      );

      setOrderSelected(newOrderTableChanged);
    },
    [orderSelected, setOrderSelected]
  );

  useEffect(() => {
    const initializeOrderSelected = orderSelected.map(orderItem => ({
      ...orderItem,
      gfpObservation: orderItem.observation || '',
      quantityQeRest:
        orderItem.recommendedQuantityPacking - orderItem.quantityPacking
    }));

    setOrderSelected(initializeOrderSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const totalPacking = orderSelected.reduce(
      (acc, orders) => acc + orders.quantityPacking,
      0
    );

    setTotalPacking(totalPacking);
  }, [orderSelected, setTotalPacking]);

  useEffect(() => {
    orderSelected.length > 1 ? setIsMultiple(true) : setIsMultiple(false);
  }, [orderSelected, setIsMultiple]);

  const handleDeleteStock = useCallback(
    (orderNumber: number) => {
      const newStocksSelected = orderSelected.filter(
        orderFilter => Number(orderFilter.orderNumber) !== Number(orderNumber)
      );
      setOrderSelected(newStocksSelected);
    },
    [setOrderSelected, orderSelected]
  );

  const openModalObservation = useCallback(
    (orderNumber: number | null) => {
      if (orderNumber !== null) {
        setCurrentOrderNumber(orderNumber);
        setShowObservationModal(true);
      }
    },
    [setShowObservationModal]
  );

  const fetchPackings = useCallback(
    (filter: FilterPackings) => {
      setPackings([]);
      const getProductPacking = new GetProductPacking();
      handlePromiseUseCase<Packing[], AxiosError>(
        getProductPacking.execute(filter),
        packingsOut => {
          setPackings(packingsOut);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, handlePromiseUseCase]
  );

  const handleChangeSelectPacking = (selectedPacking: any, index: number) => {
    handleChangeStocks(selectedPacking?.qe || '', 'quantityPacking', index);
    handleChangeStocks(selectedPacking?.qpe || '', 'quantityPerPacking', index);
    handleChangeStocks(selectedPacking?.code || '', 'packingCode', index);
    handleChangeStocks(selectedPacking?.id || '', 'packingId', index);
  };

  const handleShowObservationGFP = () => {
    const orderNumbers = orderSelected.map(order => order.orderNumber);
    const hasDifferentOrderNumbers = orderNumbers.some((orderNumber, index) => {
      return index > 0 && orderNumber !== orderNumbers[0];
    });

    return !hasDifferentOrderNumbers;
  };

  const columns: any = [
    {
      title: '',
      align: 'center',
      key: 'sort'
    },
    {
      title: 'Lotes',
      align: 'center',
      key: 'key',
      render: (value: any, record: Order, index: number) => {
        const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
        const adjustedIndex =
          (index + (currentPage - 1) * pageSizes) % letters.length;
        return letters[adjustedIndex];
      }
    },
    {
      key: 'orderId',
      title: 'Pedido',
      dataIndex: 'orderNumber',
      align: 'center'
    },
    {
      key: 'deliveryDate',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Data Entrega</StyledText>
            <SpanLabel>Horário</SpanLabel>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'deliveryDate',
      align: 'center',
      width: 100,
      render: (value: any, record: Order) => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>
              {moment(record.deliveryDate).format(`DD/MM/YYYY`)}
            </StyledText>
            <SpanLabel>{record.deliveryDateTime}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'commercialDate',
      title: () => {
        return (
          <>
            <StyledText>Data</StyledText>
            <StyledText>Comercial</StyledText>
          </>
        );
      },
      dataIndex: 'commercialDate',
      align: 'center',
      render: (commercialDate: Date) => {
        return moment(commercialDate).format(`DD/MM/YYYY`);
      }
    },
    {
      key: 'customerAccount',
      title: 'Cliente',
      dataIndex: 'customerAccount',
      align: 'center',
      render: (value: any, record: Order) => {
        return (
          <Tooltip title={record.customerName}>
            {record.customerAccount}
          </Tooltip>
        );
      }
    },
    {
      key: 'productDescription',
      title: 'Descrição',
      dataIndex: 'productDescription',
      render: (value: any, record: Order) => {
        return (
          <Tooltip title={record.productDescription}>
            <StyledText>
              {record.productDescription?.slice(0, 25) +
                (record.productDescription?.length > 10 ? '...' : '')}
            </StyledText>

            <SpanLabel>{record.productCode}</SpanLabel>
          </Tooltip>
        );
      }
    },
    {
      key: 'quality',
      title: 'Qld.',
      dataIndex: 'quality',
      align: 'center',
      render: (value: any) => {
        return <QualityContainer quality={value}>{value}</QualityContainer>;
      }
    },
    {
      key: 'packingId',
      title: 'Emb.',
      dataIndex: 'packingId',
      align: 'center',
      render: (value: any, record: Order, index: number) => {
        return (
          <Select
            defaultValue={record.packingCode}
            style={{ width: '80px' }}
            filterOption
            showSearch
            onChange={(selectedPacking: any) => {
              handleChangeSelectPacking(selectedPacking, index);

              const packingSelected = packings.find(
                pack => `${pack.code} - ${pack.description}` === selectedPacking
              );
              handlePackingChange(
                selectedPacking,
                index,
                packingSelected?.id as number
              );
            }}
            onClick={() => {
              const filterProduct = {
                siteId: siteIdSelected,
                productId: record.productId
              };
              fetchPackings(filterProduct);
            }}
          >
            {packings &&
              packings.map(packing => (
                <Select.Option
                  key={packing.id}
                  value={`${packing.code} - ${packing.description}`}
                >
                  {packing.code} - {packing.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'quantityQeRest',
      title: () => {
        return (
          <>
            <StyledText>QE</StyledText>
            <SpanLabel>Rest.</SpanLabel>
          </>
        );
      },
      dataIndex: 'quantityQeRest',
      align: 'center',
      render: (value: any, record: Order) => {
        return (
          <span>
            {record.quantityQeRest ??
              record.recommendedQuantityPacking - record.quantityPacking}
          </span>
        );
      }
    },
    {
      key: 'quantityPacking',
      title: 'QE',
      dataIndex: 'quantityPacking',
      align: 'center',
      render: (value: any, record: Order, index: number) => {
        return (
          <Input
            min="1"
            maxLength={record.recommendedQuantityPacking}
            style={{ width: '50px', textAlign: 'center' }}
            value={record.quantityPacking}
            key={record.quantityPacking}
            defaultValue={record.quantityPacking}
            onKeyDown={e => onlyNumber(e)}
            onChange={event =>
              handleQeChange(event.target.value, 'quantityPacking', index)
            }
          />
        );
      }
    },
    {
      key: 'unitPrice',
      title: 'Valor Unit.',
      dataIndex: 'unitPrice',
      align: 'center',
      width: 80,
      render: (value: number) => {
        return value?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        });
      }
    },
    {
      key: 'observationId1',
      dataIndex: 'observationId1',
      title: 'Obs 1.',
      align: 'center',
      render: (text: any, record: Order, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '220px' }}
            filterOption
            optionFilterProp="children"
            placeholder="Selecione"
            style={{ maxWidth: 90, width: 90 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeGFP(value.value ?? '', 'observationId1', index);
            }}
            defaultValue={record.observationId1 ? [record.observationId1] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observationId2',
      dataIndex: 'observationId2',
      title: 'Obs 2.',
      align: 'center',
      render: (text: any, record: Order, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '220px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{ maxWidth: 90, width: 90 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeGFP(value.value ?? '', 'observationId2', index);
            }}
            defaultValue={record.observationId2 ? [record.observationId2] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observation',
      dataIndex: 'observation',
      title: 'Obs.',
      render: (text: string) => {
        let displayText = text;
        if (text && text.length > 15) {
          displayText = `${text.substring(0, 15)}...`;
        }
        // eslint-disable-next-line no-control-regex
        const hasSpecialCharacters = /[^\x00-\x7F]/.test(displayText);
        if (hasSpecialCharacters) {
          displayText = JSON.stringify(displayText);
        }
        return (
          <Tooltip title={text}>
            <span>{displayText}</span>
          </Tooltip>
        );
      }
    },
    {
      key: 'gfpObservation',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Obs</StyledText>
            <StyledText>GFP</StyledText>
          </GroupTitleInformation>
        );
      },
      align: 'center',
      hidden: !isMultiple || handleShowObservationGFP(),
      render: (text: any, record: Order) => {
        return (
          <>
            <CommentOutlined
              style={{
                fontSize: '16px',
                color: '#000',
                cursor: 'pointer'
              }}
              onClick={() => {
                openModalObservation(record.orderId)
              }}
            />
          </>
        );
      }
    },
    {
      key: 'delete',
      align: 'center',
      render: (index: number, record: Order) => {
        return (
          <DeleteOutlined
            style={{
              fontSize: '16px',
              color: '#000',
              cursor: 'pointer'
            }}
            onClick={() => handleDeleteStock(record.orderNumber)}
          />
        );
      }
    }
  ].filter(item => !item.hidden);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = orderSelected.findIndex(
        item => item.orderId === active.id
      );
      const newIndex = orderSelected.findIndex(
        item => item.orderId === over?.id
      );
      const newItems = arrayMove(orderSelected, oldIndex, newIndex);
      setOrderSelected(newItems);
    }
  };

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={orderSelected.map(orders => orders.orderId)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            columns={columns}
            dataSource={orderSelected}
            rowKey="orderId"
            components={{
              body: {
                row: Row
              }
            }}
            pagination={{
              current: currentPage,
              pageSize: pageSizes,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </SortableContext>
      </DndContext>

      <ObservationModal
        isModalVisible={showObservationModal}
        setIsModalVisible={() => setShowObservationModal(false)}
        orderNumber={currentOrderNumber}
        initialObservation={
          orderSelected.find(order => order.orderNumber === currentOrderNumber)
            ?.gfpObservation || ''
        }
        onObservationChange={(newObservation: string) => {
          const updatedOrders = orderSelected.map(order => {
            if (order.orderNumber === currentOrderNumber) {
              return { ...order, gfpObservation: newObservation };
            }
            return order;
          });
          setOrderSelected(updatedOrders);
        }}
      />
    </>
  );
}
