import { FormInstance, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import produce from 'immer';
import { create } from 'zustand';
import Site from '~/@core/domain/model/site/Site';
import { SiteService } from '~/@core/infrastructure/api/services';
import { PermissionNames } from '~/constants/permissionNames';
import AutoCategory from '~/domain/model/ServiceRequests/AutoCategory';
import Category from '~/domain/model/ServiceRequests/Category';
import SubCategory from '~/domain/model/ServiceRequests/SubCategory';
import {
  checkIfDeskManagerUserIsValid,
  createServiceRequest,
  getServiceRequestsAutoCategories,
  getServiceRequestsCategories,
  getServiceRequestsSubCategories,
  ICreateServiceRequestParams,
  IFetchParams,
  ISendAttachmentFileParams,
  IServiceRequestResponse,
  sendAttachmentFile
} from '~/services/producer/ServiceRequests/ServiceRequestsService';

export type IActions = {
  setData: (fn: (state: IServiceRequests) => void) => void;
  setFormInstance: (form: Partial<FormInstance<IFormFilter>>) => void;
  getSites: () => void;
  getFilters: () => Promise<void>;
  getCategories: () => void;
  getSubCategories: () => void;
  getAutoCategories: () => void;
  isEmailValid: (email: string) => Promise<boolean>;
  validateEmailAndLoadFilters: (email: string) => void;
  sendAttachmentFile: (file: UploadChangeParam<UploadFile>) => void;
  createServiceRequest: () => Promise<IServiceRequestResponse>;
  resetFilters: () => void;
};

export type ISelectors = {
  isFiltersSuccessful: () => boolean;
};

export type IFormFilter = {
  siteId: number | null;
  categoryId: number | null;
  subCategoryId: number | null;
  autoCategoryId: number | null;
  description: string | null;
};

export type IFilters = {
  sites: {
    data: Site[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  categories: {
    data: Category[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  subCategories: {
    data: SubCategory[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  autoCategories: {
    data: AutoCategory[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  attachments: {
    data: UploadFile[];
    isLoading: boolean;
  };
  email: {
    data: string;
    isValid: boolean;
  };

  formFilter: IFormFilter;
};

export type IServiceRequests = {
  filters: IFilters;
  formFilterInstance: Partial<FormInstance<IFormFilter>> | null;
} & IActions &
  ISelectors;

export const initialStateFilters: IFilters = {
  sites: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: ''
  },
  categories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: ''
  },
  subCategories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: ''
  },
  autoCategories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: ''
  },
  attachments: {
    data: [],
    isLoading: false
  },
  email: {
    data: '',
    isValid: false
  },

  formFilter: {
    siteId: null,
    categoryId: null,
    subCategoryId: null,
    autoCategoryId: null,
    description: null
  }
};

const useServiceRequestsStore = create<IServiceRequests>()((set, get) => ({
  // States
  filters: initialStateFilters,
  formFilterInstance: null,

  // Setters
  setData: fn => set(produce(fn)),
  setFormInstance: (form: Partial<FormInstance<IFormFilter>>) => {
    set({ formFilterInstance: form });
  },

  // Selectors
  isFiltersSuccessful: () => {
    const { sites, categories, subCategories, autoCategories } = get().filters;
    return (
      sites.isSuccessful &&
      categories.isSuccessful &&
      subCategories.isSuccessful &&
      autoCategories.isSuccessful
    );
  },

  // Actions
  getSites: async () => {
    const { setData } = get();

    try {
      setData(state => {
        state.filters.sites.isLoading = true;
        state.filters.sites.isSuccessful = false;
        state.filters.sites.isError = false;
      });

      const PermissionNameTagCart = PermissionNames.Gfp.MenuTagCart;
      const data = await SiteService.fetchSites(PermissionNameTagCart);

      setData(state => {
        state.filters.sites.data = data;
        state.filters.sites.isSuccessful = true;
        state.filters.sites.isError = false;
      });
      return Promise.resolve(data);
    } catch (error) {
      setData(state => {
        state.filters.sites.data = [];
        state.filters.sites.isSuccessful = false;
        state.filters.sites.isError = true;
      });
      return Promise.reject(error);
    } finally {
      setData(state => {
        state.filters.sites.isLoading = false;
      });
    }
  },
  getCategories: async () => {
    const { setData } = get();

    setData(state => {
      state.filters.categories.isLoading = true;
      state.filters.categories.isSuccessful = false;
      state.filters.categories.isError = false;
    });

    try {
      const params: IFetchParams = {
        Pesquisa: '',
        Ativo: 'S'
      };

      const { data } = await getServiceRequestsCategories(params);

      setData(state => {
        state.filters.categories.data = data;
        state.filters.categories.isSuccessful = true;
        state.filters.categories.isError = false;
      });
    } catch (error) {
      setData(state => {
        state.filters.categories.data = [];
        state.filters.categories.isSuccessful = false;
        state.filters.categories.isError = true;
      });
    } finally {
      setData(state => {
        state.filters.categories.isLoading = false;
      });
    }
  },
  getSubCategories: async () => {
    const { setData } = get();

    setData(state => {
      state.filters.subCategories.isLoading = true;
      state.filters.subCategories.isSuccessful = false;
      state.filters.subCategories.isError = false;
    });

    try {
      const categoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('categoryId') ?? null;

      const categoryNameSelected =
        get().filters.categories.data.find(
          i => i.account === categoryIdSelected
        )?.name || '';

      const params: IFetchParams = {
        Pesquisa: categoryNameSelected,
        Ativo: 'S'
      };

      const { data } = await getServiceRequestsSubCategories(params);

      setData(state => {
        state.filters.subCategories.data = data;
        state.filters.subCategories.isSuccessful = true;
        state.filters.subCategories.isError = false;
      });
    } catch (error) {
      setData(state => {
        state.filters.subCategories.data = [];
        state.filters.subCategories.isSuccessful = false;
        state.filters.subCategories.isError = true;
      });
    } finally {
      setData(state => {
        state.filters.subCategories.isLoading = false;
      });
    }
  },
  getAutoCategories: async () => {
    const { setData } = get();

    setData(state => {
      state.filters.autoCategories.isLoading = true;
      state.filters.autoCategories.isSuccessful = false;
      state.filters.autoCategories.isError = false;
    });

    try {
      const categoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('categoryId') ?? null;
      const subCategoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('subCategoryId') ?? null;

      const categoryNameSelected =
        get().filters.categories.data.find(
          category => category.account === categoryIdSelected
        )?.name || '';
      const subCategoryNameSelected =
        get().filters.subCategories.data.find(
          subCategory => subCategory.account === subCategoryIdSelected
        )?.name || '';

      const params: IFetchParams = {
        Pesquisa:
          categoryNameSelected && subCategoryNameSelected
            ? `${categoryNameSelected} - ${subCategoryNameSelected}`
            : categoryNameSelected || subCategoryNameSelected || '',
        Ativo: 'S'
      };

      const { data } = await getServiceRequestsAutoCategories(params);

      setData(state => {
        state.filters.autoCategories.data = data;
        state.filters.autoCategories.isSuccessful = true;
        state.filters.autoCategories.isError = false;
      });
    } catch (error) {
      setData(state => {
        state.filters.autoCategories.data = [];
        state.filters.autoCategories.isSuccessful = false;
        state.filters.autoCategories.isError = true;
      });
    } finally {
      setData(state => {
        state.filters.autoCategories.isLoading = false;
      });
    }
  },
  getFilters: async () => {
    const { getSites, getCategories, getSubCategories, getAutoCategories } =
      get();
    try {
      await Promise.all([
        getSites(),
        getCategories(),
        getSubCategories(),
        getAutoCategories()
      ]);
      return Promise.resolve();
    } catch (error) {
      console.error('Error ao carregar filtros.', error);
      return Promise.reject(error);
    }
  },
  resetFilters: () => {
    const { setData } = get();
    setData(state => {
      state.filters = initialStateFilters;
    });
  },
  isEmailValid: async (email: string) => {
    try {
      const response = await checkIfDeskManagerUserIsValid(email);
      const isUserValid = response.data;

      if (!isUserValid) {
        return Promise.reject();
      }
      return Promise.resolve(true);
    } catch (error) {
      console.error('Erro ao validar usuário.', error);
      return Promise.reject();
    }
  },
  validateEmailAndLoadFilters: async (email: string) => {
    const { setData, isEmailValid, getFilters } = get();
    const userExists = await isEmailValid(email);

    if (userExists) {
      getFilters();
      setData(state => {
        state.filters.email.data = email;
        state.filters.email.isValid = true;
      });
    } else {
      throw new Error(
        `O e-mail ${email} não foi cadastrado na central de suporte.`
      );
    }
  },
  sendAttachmentFile: async (file: UploadChangeParam<UploadFile>) => {
    const { setData } = get();

    setData(state => {
      state.filters.attachments.data = file.fileList.map(f => {
        if (f.uid === file.file.uid) {
          return { ...f, status: 'uploading' };
        }
        return f;
      });
    });

    try {
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.file.originFileObj as Blob);
        reader.onload = () => {
          const base64String = (reader.result as string).split(',')[1];
          resolve(base64String);
        };
        reader.onerror = error => reject(error);
      });

      const params: ISendAttachmentFileParams = {
        Base64: base64 as string,
        Type: file.file.type as string,
        FileName: file.file.name,
        FileSize: String(file.file.size)
      };

      const { data } = await sendAttachmentFile(params);

      console.log('data >', data);

      setData(state => {
        state.filters.attachments.data = file.fileList.map(f => {
          if (f.uid === file.file.uid) {
            return { ...f, status: 'done' };
          }
          return f;
        });
      });
    } catch (error) {
      console.error('Error ao enviar arquivo.', error);

      setData(state => {
        state.filters.attachments.data = file.fileList.map(f => {
          if (f.uid === file.file.uid) {
            return { ...f, status: 'error' };
          }
          return f;
        });
      });
    }
  },
  createServiceRequest: async () => {
    const { setData } = get();

    setData(state => {
      state.filters.attachments.isLoading = true;
    });

    const email = get().filters.email.data;

    const autoCategoryAccountSelected =
      get().formFilterInstance?.getFieldValue?.('autoCategoryId') ?? null;

    const selectedCategoryName = get().filters.autoCategories.data.find(
      autoCategory => autoCategory.account === autoCategoryAccountSelected
    )?.name;

    const description =
      get().formFilterInstance?.getFieldValue?.('description');

    try {
      const params: ICreateServiceRequestParams = {
        Email: email,
        Solicitacao: '0001',
        Assunto: selectedCategoryName as string,
        AutoCategoria: autoCategoryAccountSelected,
        Descricao: description
        // TODO: implementar campos extras
        // TCampoExtra: {
        //   Campo8756: '170211'
        // }
      };

      const { data } = await createServiceRequest(params);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}));

export default useServiceRequestsStore;
