import { Col, DatePicker, Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ShippingCompanySelect from '~/components/atoms/Inputs/ShippingCompanySelect';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { brFormat } from '~/helpers/util';
import { FiltersTableRow } from './style';

interface Props {
  form: FormInstance<any>;
}

export default function FormAddKlokGFP(props: Props) {
  const { form } = props;
  const { t } = useTranslation();

  const { siteIdSelected } = useContext(globalContext);
  const { outDate, setOutDate } = useContext(GFPContext);

  function disabledDate(current: Moment) {
    return current && current < moment().startOf('day');
  }

  const disableComercialDate = useCallback(
    (value: Moment) => {
      const dayOfWeek = value.day();
      const outDateValue = form.getFieldValue('outDate');
      const today = moment().startOf('day');

      if (value.isSame(today, 'day') && [1, 2, 3, 5].includes(dayOfWeek)) {
        return false;
      }

      if (dayOfWeek === 0 || dayOfWeek === 4) {
        return true;
      }

      if (outDateValue) {
        const outDayOfWeek = moment(outDateValue).day();
        const maxDaysMap: { [dayOfWeek: number]: number } = {
          0: 3,
          1: 3,
          2: 3,
          3: 2,
          4: 3,
          5: 3,
          6: 3
        };

        const maxDays = maxDaysMap[outDayOfWeek];
        const enabledDates: string[] = [];
        const currentDate = moment(outDateValue);

        while (enabledDates.length < maxDays) {
          currentDate.add(1, 'day');
          const currentDayOfWeek = currentDate.day();
          if (currentDayOfWeek !== 0 && currentDayOfWeek !== 4) {
            enabledDates.push(currentDate.format('YYYY-MM-DD'));
          }
        }

        return !enabledDates.includes(value.format('YYYY-MM-DD'));
      }

      return true;
    },
    [form]
  );

  useEffect(() => {
    if (outDate === null) {
      setOutDate(form.getFieldValue('outDate'));
    }
  }, []);

  const handleChangeOutDate = () => {
    form.setFieldValue('commercialDate', null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    if (
      !/^[0-9]$/.test(key) &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('Text');
    if (!/^[0-9]+$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <FiltersTableRow justify="space-between">
        <Col lg={6}>
          <Form.Item
            label={t('gfp.modal.addGfp.form.emissionDate')}
            name="emissionDate"
          >
            <DatePicker
              picker="date"
              placeholder={t('generals.selectPlaceholder')}
              format={brFormat}
              style={{ width: '100%' }}
              disabled
              key={23424}
            />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            label={t('gfp.modal.addGfp.form.outDate')}
            name="outDate"
            rules={[{ required: true, message: 'Selecione a data' }]}
          >
            <DatePicker
              picker="date"
              placeholder={t('generals.selectPlaceholder')}
              format={brFormat}
              style={{ width: '100%' }}
              key={23425}
              onBlur={date => setOutDate(moment(date.target.value))}
              disabledDate={disabledDate}
              onChange={handleChangeOutDate}
            />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            label={t('gfp.modal.addGfp.form.commercialDate')}
            name="commercialDate"
            rules={[{ required: true, message: 'Selecione a data' }]}
          >
            <DatePicker
              disabledDate={disableComercialDate}
              picker="date"
              allowClear
              placeholder={t('generals.selectPlaceholder')}
              format={brFormat}
              style={{ width: '100%' }}
              key={23428}
            />
          </Form.Item>
        </Col>
        <Col lg={5}>
          <Form.Item
            label={t('gfp.modal.addGfp.form.local')}
            name="pickupLocation"
          >
            <Select disabled>
              <Select.Option key={1} value={1}>
                CVH
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </FiltersTableRow>
      <FiltersTableRow
        style={{ marginTop: '-15px', gap: '10px', justifyContent: 'left' }}
      >
        <Col>
          <Form.Item
            label={t('gfp.modal.addGfp.form.divider')}
            name="partitionQuantity"
            rules={[{ required: true, message: 'Insira uma divisória' }]}
          >
            <InputNumber
              min={0}
              onPaste={handlePaste}
              parser={value =>
                value ? parseInt(value.replace(/[^0-9]/g, ''), 10) : 0
              }
              onKeyDown={handleKeyDown}
              style={{ marginLeft: '18px', width: '222px' }}
              type="text"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={t('gfp.modal.addGfp.form.multiply')}
            name="multiple"
            style={{ width: '316px' }}
          >
            <InputNumber
              min={0}
              max={30}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              parser={value =>
                value ? parseInt(value.replace(/[^0-9]/g, ''), 10) : 1
              }
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={t('gfp.modal.addGfp.form.transport')}
            name="transporterId"
            style={{ marginLeft: '13px' }}
            rules={[{ required: true, message: 'Insira uma transportadora' }]}
          >
            <ShippingCompanySelect
              form={form}
              siteId={siteIdSelected}
              style={{ width: '250px' }}
              formFieldName="transporterId"
            />
          </Form.Item>
        </Col>
      </FiltersTableRow>
    </>
  );
}
