/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseOutlined,
  ExclamationCircleOutlined,
  HistoryOutlined,
  MessageOutlined,
  MinusOutlined,
  MoreOutlined,
  PlusOutlined,
  PrinterOutlined,
  StopOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormInstance,
  Row,
  Table,
  TableColumnsType,
  Tooltip
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { setTimeout } from 'timers';
import { useShallow } from 'zustand/react/shallow';
import CancelGfp from '~/@core/application/useCases/gfp/cancelGfp';
import GetGfpPrint from '~/@core/application/useCases/gfp/getGfpPrint';
import GfpCartItems from '~/@core/domain/model/gfp/GfpCartItems';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import IGfp from '~/@core/domain/model/gfp/IGfp';

import Partial from '~/assets/svgs/Partial.svg';
import ButtonPrinter from '~/components/atoms/Buttons/ButtonPrint';
import PanelFilters from '~/components/atoms/Inputs/PanelFilters';
import { QualityContainer } from '~/components/atoms/QualityContainer';
import {
  DividerFrame,
  ModalBody,
  ModalDefault,
  ModalFooter,
  ModalHeader
} from '~/components/Globals';
import { ConfirmModal } from '~/components/Modals/ConfirmModal';
import { Subtitles } from '~/components/pages/SalesReportEcommerce/styles';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { GfpManagementEnum } from '~/domain/enum/gfpManagement.enum';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import GenerateGFP from '../../Modals/ExportPDFModal/GenerateGFP';
import { PrintContainer } from '../../Modals/ExportPDFModal/styles';
import TableDropdownGfp from '../../TableDropdowndGfp';
import {
  CardContainer,
  FlexContent,
  FrameStyled,
  StyledTable,
  StyledText,
  Title
} from './style';

interface Props {
  form: FormInstance<any>;
}

export default function TableManagement(props: Props) {
  const { form } = props;
  const { t } = useTranslation();
  const {
    gfpCarts,
    setGfpCarts,
    setIsPageLoading,
    orderPagination,
    isPageLoading,
    gfpCartsPagination,
    setGfpCartsPagination
  } = useContext(GFPContext);

  const {
    pagination,
    setPagination,
    selectedRowKeys,
    selectedGfpIds,
    setSelectedGfpIds,
    // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
    // handleSearch,
    setDataStore,
    formFilterInstance,
    tableParams,
    isLoading,
    dataSource
  } = useGfpStore(
    useShallow(state => ({
      filters: state.filters,
      pagination: state.pagination,
      setPagination: state.setPagination,
      selectedRowKeys: state.selectedRowKeys,
      selectedGfpIds: state.selectedGfpIds,
      setSelectedGfpIds: state.setSelectedGfpIds,
      // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
      // handleSearch: state.handleSearchCarts,
      setDataStore: state.setData,
      formFilterInstance: state.formFilterInstance,
      tableParams: state.tableParams,
      isLoading: state.table.isLoading,
      dataSource: state.table.data
    }))
  );

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  const [isPrintSuccessful, setIsPrintSuccessful] = useState<boolean>(false);
  const [showGenerateGFP, setShowGenerateGFP] = useState<boolean>(false);
  const [printedRows, setPrintedRows] = useState<React.Key[]>([]);
  const [gfp, setGfp] = useState<IGfp[]>([]);
  const [isGrouped, setIsGrouped] = useState<boolean>(false);

  const printRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentObservation, setCurrentObservation] = useState('');
  const [selectedGfpId, setSelectedGfpId] = useState<number | null>(null);
  const [modalConfirmationIsVisible, setModalConfirmationIsVisible] =
    useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const resetSelection = () => {
    setSelectedGfpIds([]);
  };

  const panelFilters = () => (
    <Form>
      <PanelFilters
        switchLabel={t('gfp.table.groupByCart')}
        switchFormName="GroupByCart"
        isInputDisabled
        form={form}
        searchFormName=""
        // FIXME
        // eslint-disable-next-line prettier/prettier
        setSearchValue={() => {}}
        isRadioEnabled
        isGroupByOrders={isGrouped}
        setIsGroupByOrders={(
          value: boolean | ((prevState: boolean) => boolean)
        ) => {
          setIsGrouped(typeof value === 'function' ? value(isGrouped) : value);
          resetSelection();
        }}
      />
    </Form>
  );

  const expandedRowRender = (record: GfpCarts) => {
    const onSelectChange2 = (newSelectedRowKeys: React.Key[]) => {
      const currentCartGfpIds = record.items.map(item => item.gfpId);

      setSelectedGfpIds(prevSelected => {
        const updatedSelection = new Set(prevSelected);

        currentCartGfpIds.forEach(id => {
          if (newSelectedRowKeys.includes(id)) {
            updatedSelection.add(id);
          } else {
            updatedSelection.delete(id);
          }
        });

        return Array.from(updatedSelection);
      });
    };

    const rowSelection2 = {
      selectedRowKeys: selectedGfpIds.filter(id =>
        record.items.some(item => item.gfpId === id)
      ),
      onChange: onSelectChange2
    };

    const expandedCarts: TableColumnsType<GfpCartItems> = [
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status: string) => {
          switch (status) {
            case GfpManagementEnum.open:
              return (
                <Tooltip title="Aberto">
                  <HistoryOutlined style={{ color: '#FAAD14' }} />
                </Tooltip>
              );
            case GfpManagementEnum.received:
              return (
                <Tooltip title="Recebido">
                  <img src={Partial} alt="partial" />
                </Tooltip>
              );
            case GfpManagementEnum.close:
              return (
                <Tooltip title="Fechado">
                  <CheckCircleOutlined style={{ color: '#52C41A' }} />
                </Tooltip>
              );
            case GfpManagementEnum.canceled:
              return (
                <Tooltip title="Cancelado">
                  <StopOutlined style={{ color: '#FF0000' }} />
                </Tooltip>
              );
            default:
              return null;
          }
        }
      },
      {
        title: 'N° GFP',
        dataIndex: 'gfpNumber',
        key: 'gfpNumber',
        align: 'center'
      },
      {
        title: 'Data Comercial',
        dataIndex: 'commercialDate',
        key: 'commercialDate',
        align: 'center',
        render: (text: string) => {
          return <>{moment(text).format('DD/MM/YYYY')}</>;
        }
      },
      {
        title: 'Cliente',
        dataIndex: 'customerName',
        key: 'customerName',
        render: (text: string, item) => {
          return (
            <div style={{ lineHeight: '20px' }}>
              <Tooltip title={item.customerName}>
                <div style={{ fontSize: '13px' }}>
                  {item.customerName && item.customerName.length > 25
                    ? `${item.customerName.slice(0, 25)}..`
                    : item.customerName || ''}
                </div>
              </Tooltip>
              <div>{item.customerCode}</div>
            </div>
          );
        }
      },
      {
        title: 'Produto',
        dataIndex: 'longDescription',
        key: 'longDescription',
        width: 200,
        render: (text: string, item) => {
          return (
            <div style={{ lineHeight: '20px' }}>
              <Tooltip title={item.longDescription}>
                <div style={{ fontSize: '13px' }}>
                  {item.longDescription && item.longDescription.length > 25
                    ? `${item.longDescription.slice(0, 25)}..`
                    : item.longDescription || ''}
                </div>
              </Tooltip>
              <div>{item.productCode}</div>
            </div>
          );
        }
      },
      {
        title: 'Qld',
        dataIndex: 'quality',
        key: 'quality',
        align: 'center',
        render: (value: any) => {
          return <QualityContainer quality={value}>{value}</QualityContainer>;
        }
      },
      {
        title: 'Emb',
        dataIndex: 'packingDescription',
        key: 'packingDescription',
        width: 120,
        render: (text: string, item) => {
          return (
            <div style={{ lineHeight: '20px' }}>
              <div style={{ fontSize: '13px' }}>{item.packingCode}</div>
              <Tooltip title={item.packingDescription}>
                <div style={{ fontSize: '13px' }}>
                  {item.packingDescription &&
                  item.packingDescription.length > 10
                    ? `${item.packingDescription.slice(0, 10)}..`
                    : item.packingDescription || ''}
                </div>
              </Tooltip>
            </div>
          );
        }
      },
      {
        title: 'QE x QpE',
        dataIndex: 'quantityPacking',
        key: 'quantityPacking',
        align: 'center',
        render: (_text: string, item: any) => (
          <>{`${item.quantityPacking} x ${item.quantityPerPacking}`}</>
        )
      },
      {
        title: 'Obs GFP',
        dataIndex: 'gfpObservation',
        key: 'gfpObservation',
        width: 50,
        align: 'center',
        render: (text: string, item) => {
          return (
            <>
              {text && text.trim() !== '' ? (
                <MessageOutlined
                  onClick={() => showModal(text, item.gfpId)}
                  style={{ cursor: 'pointer', fontSize: '18px' }}
                />
              ) : (
                <CloseOutlined style={{ color: '#6666', fontSize: '18px' }} />
              )}
            </>
          );
        }
      },
      {
        title: 'Multi-boleta',
        dataIndex: 'multiQuantity',
        key: 'multiQuantity',
        width: 80,
        align: 'center',
        render: (value: any, item, index: number) => (
          <span
            style={{ color: '#FFA500' }}
          >{`${item.multiQuantity} de ${item.multiQuantityTotal}`}</span>
        )
      },
      {
        key: 'print',
        dataIndex: 'print',
        width: 100,
        align: 'center',
        render: (_text, item: any) => {
          const isPrinted = record.printed === true;

          return (
            <>
              <PrinterOutlined
                style={{ margin: '0 20px 0 0', color: '#BFBFBF' }}
                onClick={() => handlePrint(item)}
              />
              {isPrinted ? (
                <CheckCircleFilled style={{ color: '#52C41A' }} />
              ) : (
                <CloseCircleFilled style={{ color: '#BFBFBF' }} />
              )}
            </>
          );
        }
      }
    ];

    return (
      <Table
        columns={expandedCarts}
        dataSource={record.items}
        pagination={false}
        rowSelection={rowSelection2}
        rowKey="gfpId"
      />
    );
  };

  useEffect(() => {
    const storedPrintedRows = JSON.parse(
      localStorage.getItem('printedRows') || '[]'
    );
    setPrintedRows(storedPrintedRows);

    const storedObservations = JSON.parse(
      localStorage.getItem('gfpObservations') || '{}'
    );
    setGfpCarts(prevCarts =>
      prevCarts.map(cart => ({
        ...cart,
        items: cart.items.map(item => ({
          ...item,
          gfpObservation: storedObservations[item.gfpId] || item.gfpObservation
        }))
      }))
    );
  }, [setGfpCarts]);

  const isCartFullySelected = (cart: GfpCarts) => {
    return cart.items.every(item => selectedGfpIds.includes(item.gfpId));
  };

  const expandAll = () => {
    const allRowKeys = gfpCarts.map(cart => cart.cartNumber);
    setExpandedRowKeys(allRowKeys);
  };

  const collapseAll = () => {
    setExpandedRowKeys([]);
  };

  const rowClassName = (record: GfpCarts) => {
    return record.printed ? 'printed-row' : '';
  };

  const viewCarts: TableColumnsType<GfpCarts> = [
    {
      key: 'colapseExpand',
      title: () => (
        <div>
          <Tooltip title="Expandir/Colapsar todas as linhas">
            <Button
              icon={
                expandedRowKeys.length === gfpCarts.length ? (
                  <MinusOutlined style={{ fontSize: 13 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 13 }} />
                )
              }
              size="small"
              style={{
                fontSize: '10px',
                width: '15px',
                height: '15px'
              }}
              onClick={() => {
                if (expandedRowKeys.length === gfpCarts.length) {
                  collapseAll();
                } else {
                  expandAll();
                }
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: 'colapseExpand',
      width: '20px',
      render: (_text, record) => {
        return (
          <Button
            icon={
              expandedRowKeys.includes(record.cartNumber) ? (
                <MinusOutlined style={{ fontSize: 13 }} />
              ) : (
                <PlusOutlined style={{ fontSize: 13 }} />
              )
            }
            size="small"
            style={{ fontSize: '10px', width: '15px', height: '15px' }}
            onClick={() => {
              if (expandedRowKeys.includes(record.cartNumber)) {
                setExpandedRowKeys(prev =>
                  prev.filter(key => key !== record.cartNumber)
                );
              } else {
                setExpandedRowKeys(prev => [...prev, record.cartNumber]);
              }
            }}
          />
        );
      }
    },
    Table.SELECTION_COLUMN,
    {
      key: 'cancelGfp',
      title: () => (
        <Dropdown
          overlay={
            <TableDropdownGfp
              onClick={() => setModalConfirmationIsVisible(true)}
              disabled={!gfpCarts.some(isCartFullySelected)}
            />
          }
          trigger={['hover']}
        >
          <MoreOutlined />
        </Dropdown>
      ),
      width: '20px',
      render: (_: string, record: GfpCarts) => (
        <Dropdown
          overlay={
            <TableDropdownGfp
              onClick={() => {
                const gfpIds = record.items.map(item => item.gfpId);
                setSelectedGfpIds(gfpIds);
                setModalConfirmationIsVisible(true);
              }}
            />
          }
          trigger={['hover']}
        >
          <MoreOutlined />
        </Dropdown>
      )
    },
    {
      key: 'cartNumber',
      title: 'Carrinho',
      dataIndex: 'cartNumber',
      width: '80px'
    },
    {
      key: 'customers',
      dataIndex: 'customers',
      title: () => {
        return <Title>Clientes</Title>;
      },
      width: '50px',
      render: customers => {
        return <div style={{ width: '300px' }}>{customers}</div>;
      }
    },
    {
      key: 'partitionQuantity',
      title: 'Divisória',
      dataIndex: 'partitionQuantity',
      align: 'center',
      width: '80px'
    },
    {
      key: 'totalPackings',
      title: 'Total de embalagens',
      dataIndex: 'totalPackings',
      width: '60px',
      align: 'center'
    },
    {
      key: 'cce',
      title: 'CC-e',
      dataIndex: 'cce',
      width: '30px',
      render: (value: boolean) => (value === false ? 'Não' : 'Sim')
    },
    { key: 'type', title: 'Tipo', dataIndex: 'type', width: 70 },
    {
      key: 'generationDate',
      title: 'Data Geração',
      dataIndex: 'generationDate',
      width: '100px',
      render: (text: string) => <>{moment(text).format('DD/MM/YYYY - HH:mm')}</>
    },
    {
      key: 'deliveryDate',
      title: 'Data Entrega',
      dataIndex: 'deliveryDate',
      width: '50px',
      render: (text: string) => <>{moment(text).format('DD/MM/YYYY')}</>
    },
    {
      key: 'print',
      dataIndex: 'print',
      align: 'center',
      render: (_text, record) => {
        const isPrinted = record.printed === true;

        return (
          <>
            <PrinterOutlined
              style={{ margin: '0 20px 0 0', color: '#BFBFBF' }}
              onClick={() => handlePrint(record)}
            />
            {isPrinted ? (
              <CheckCircleFilled style={{ color: '#52C41A' }} />
            ) : (
              <CloseCircleFilled style={{ color: '#BFBFBF' }} />
            )}
          </>
        );
      }
    }
  ];

  const showModal = (observation: string, gfpId: number) => {
    setCurrentObservation(observation);
    setSelectedGfpId(gfpId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const savedPrintedRows = localStorage.getItem('printedRows');
    if (savedPrintedRows) {
      setPrintedRows(JSON.parse(savedPrintedRows));
    }
  }, []);

  const viewGfps: TableColumnsType<any> = [
    {
      key: 'cancelGfp',
      title: (cart: GfpCarts) => (
        <Dropdown
          overlay={
            <TableDropdownGfp
              onClick={() => setModalConfirmationIsVisible(true)}
              disabled={selectedGfpIds.length < 2}
            />
          }
          trigger={['hover']}
        >
          <MoreOutlined />
        </Dropdown>
      ),
      width: 50,
      render: (_: string, record: GfpCarts) => (
        <Dropdown
          overlay={
            <TableDropdownGfp
              onClick={() => {
                const gfpIds = record.items.map(item => item.gfpId);
                setSelectedGfpIds(gfpIds);
                setModalConfirmationIsVisible(true);
              }}
            />
          }
          trigger={['hover']}
        >
          <MoreOutlined />
        </Dropdown>
      )
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: 40,
      render: (status: string) => {
        switch (status) {
          case GfpManagementEnum.open:
            return (
              <Tooltip title="Aberto">
                <HistoryOutlined style={{ color: '#FAAD14' }} />
              </Tooltip>
            );
          case GfpManagementEnum.received:
            return (
              <Tooltip title="Recebido">
                <img src={Partial} alt="partial" />
              </Tooltip>
            );
          case GfpManagementEnum.close:
            return (
              <Tooltip title="Fechado">
                <CheckCircleOutlined style={{ color: '#52C41A' }} />
              </Tooltip>
            );
          case GfpManagementEnum.canceled:
            return (
              <Tooltip title="Cancelado">
                <StopOutlined style={{ color: '#FF0000' }} />
              </Tooltip>
            );
          default:
            return null;
        }
      }
    },
    {
      key: 'cartNumber',
      title: 'Car',
      dataIndex: 'cartNumber',
      width: 60,
      align: 'center',
      render: (_, record) => {
        const cart = (dataSource.length ? dataSource : gfpCarts).find(carts =>
          carts.items.some(item => item.gfpId === record.gfpId)
        );
        return cart?.cartNumber || '';
      }
    },
    { title: 'N° GFP', dataIndex: 'gfpNumber', key: 'gfpNumber', width: 80 },
    {
      key: 'cce',
      title: 'CC-e',
      dataIndex: 'cce',
      width: 70,
      align: 'center',
      render: (_, record) => {
        const cce = gfpCarts.find(cces =>
          cces.items.some(item => item.gfpId === record.gfpId)
        );
        return cce?.cce === false ? 'Não' : 'Sim';
      }
    },
    {
      key: 'type',
      title: 'Tipo',
      dataIndex: 'type',
      width: 70,
      align: 'center',
      render: (_, record) => {
        const type = (dataSource.length ? dataSource : gfpCarts).find(types =>
          types.items.some(item => item.gfpId === record.gfpId)
        );
        return type?.type;
      }
    },
    {
      key: 'generationDate',
      title: () => (
        <div>
          <div>Data</div>
          <div>Geração</div>
        </div>
      ),
      dataIndex: 'generationDate',
      width: 80,
      align: 'center',
      render: (text: string) => (
        <div>
          <div>{moment(text).format('DD/MM/YYYY')}</div>
          <div
            style={{ fontSize: '0.8em', textAlign: 'center', color: '#666666' }}
          >
            {moment(text).format('HH:mm')}
          </div>
        </div>
      )
    },
    {
      key: 'deliveryDate',
      title: () => (
        <div>
          <div>Data</div>
          <div>Entrega</div>
        </div>
      ),
      dataIndex: 'deliveryDate',
      width: 80,
      align: 'center',
      render: (text: string) => (
        <div>
          <div>{moment(text).format('DD/MM/YYYY')}</div>
          <div
            style={{ fontSize: '0.8em', textAlign: 'center', color: '#666666' }}
          >
            {moment(text).format('HH:mm')}
          </div>
        </div>
      )
    },
    {
      key: 'commercialDate',
      title: 'Data Comercial',
      dataIndex: 'commercialDate',
      width: 100,
      align: 'center',
      render: (text: string) => (
        <div>
          <div>{moment(text).format('DD/MM/YYYY')}</div>
        </div>
      )
    },
    {
      title: 'Cliente',
      dataIndex: 'customerCode',
      key: 'customerCode',
      width: 60,
      align: 'center',
      render: (text: string, item) => {
        return (
          <Tooltip title={item.customerName}>
            <div>{item.customerCode}</div>
          </Tooltip>
        );
      }
    },
    {
      title: 'Produto',
      dataIndex: 'longDescription',
      key: 'longDescription',
      width: 200,
      render: (text: string, record: GfpCarts) => {
        return (
          <div style={{ lineHeight: '20px' }}>
            <Tooltip title={record.longDescription}>
              <div style={{ fontSize: '13px' }}>
                {record.longDescription && record.longDescription.length > 25
                  ? `${record.longDescription.slice(0, 25)}..`
                  : record.longDescription || ''}
              </div>
            </Tooltip>
            <div>{record.productCode}</div>
          </div>
        );
      }
    },
    {
      title: 'Qld',
      dataIndex: 'quality',
      key: 'quality',
      align: 'center',
      width: 70,
      render: (value: any) => {
        return <QualityContainer quality={value}>{value}</QualityContainer>;
      }
    },
    {
      title: 'Emb',
      dataIndex: 'packingDescription',
      key: 'packingDescription',
      width: 120,
      render: (text: string, record: GfpCarts) => {
        return (
          <div style={{ lineHeight: '20px' }}>
            <div style={{ fontSize: '13px' }}>{record.packingCode}</div>
            <Tooltip title={record.packingDescription}>
              <div style={{ fontSize: '13px' }}>
                {record.packingDescription &&
                record.packingDescription.length > 10
                  ? `${record.packingDescription.slice(0, 10)}..`
                  : record.packingDescription || ''}
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: 'QE x QpE',
      dataIndex: 'quantityPacking',
      key: 'quantityPacking',
      align: 'center',
      width: 80,
      render: (_text: string, item: any) => (
        <>{`${item.quantityPacking} x ${item.quantityPerPacking}`}</>
      )
    },
    {
      title: () => (
        <div>
          <div>Obs</div>
          <div>GFP</div>
        </div>
      ),
      dataIndex: 'gfpObservation',
      key: 'gfpObservation',
      width: 50,
      align: 'center',
      render: (text: string, record: any) => {
        return (
          <>
            {text && text.trim() !== '' ? (
              <MessageOutlined
                onClick={() => showModal(text, record.gfpId)}
                style={{ cursor: 'pointer', fontSize: '18px' }}
              />
            ) : (
              <CloseOutlined style={{ color: '#6666', fontSize: '18px' }} />
            )}
          </>
        );
      }
    },
    {
      title: 'Multi-GFP',
      dataIndex: 'multiQuantity',
      key: 'multiQuantity',
      width: 80,
      align: 'center',
      render: (value: any, item, index: number) => {
        return (
          <span
            style={{ color: '#FFA500' }}
          >{`${item.multiQuantity} de ${item.multiQuantityTotal}`}</span>
        );
      }
    },
    {
      key: 'print',
      dataIndex: 'print',
      width: 100,
      align: 'center',
      render: (_text, record) => {
        const isPrinted = record.printed === true;

        return (
          <>
            <PrinterOutlined
              style={{ margin: '0 20px 0 0', color: '#BFBFBF' }}
              onClick={() => handlePrint(record)}
            />
            {isPrinted ? (
              <CheckCircleFilled style={{ color: '#52C41A' }} />
            ) : (
              <CloseCircleFilled style={{ color: '#BFBFBF' }} />
            )}
          </>
        );
      }
    }
  ];

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: GfpCarts[]
  ) => {
    const allSelectedGfpIds = new Set<React.Key>();

    selectedRows.forEach(cart => {
      cart.items.forEach(item => {
        allSelectedGfpIds.add(item.gfpId);
      });
    });

    setSelectedGfpIds(Array.from(allSelectedGfpIds));
  };

  const rowSelection = {
    selectedRowKeys: selectedGfpIds,
    onChange: onSelectChange,
    getCheckboxProps: (record: GfpCarts) => ({
      indeterminate:
        record.items.some(item => selectedGfpIds.includes(item.gfpId)) &&
        !record.items.every(item => selectedGfpIds.includes(item.gfpId))
    })
  };

  const handlePrintGFP = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setIsPrintSuccessful(true);
      setPrintedRows(prev => [...prev, ...selectedRowKeys, ...selectedGfpIds]);
      resetSelection();
    }
  });

  const handlePrint = (record: GfpCarts) => {
    handlePrintGFP();
    const updatedPrintedRows = [...printedRows, record.gfpId];
    setPrintedRows(updatedPrintedRows);
    localStorage.setItem('printedRows', JSON.stringify(updatedPrintedRows));
  };

  const onSelectChangeNoGroup = (
    newSelectedRowKeys: React.Key[],
    selectedRows: GfpCarts[]
  ) => {
    const prevSelectedKeys = selectedGfpIds;

    selectedRows.forEach(row => {
      const cartNumber = row.cartNumber;
      const sameCartKeys = dataSource
        .filter(item => item.cartNumber === cartNumber)
        .flatMap(item => item.items.map(i => i.gfpId));

      const allWereSelected = sameCartKeys.every(key =>
        newSelectedRowKeys.includes(key)
      );

      if (!allWereSelected) {
        sameCartKeys.forEach(key => {
          if (newSelectedRowKeys.includes(key)) return;
          newSelectedRowKeys = newSelectedRowKeys.filter(id => id !== key);
        });
      } else {
        sameCartKeys.forEach(key => {
          if (!newSelectedRowKeys.includes(key)) {
            newSelectedRowKeys.push(key);
          }
        });
      }
    });

    setSelectedGfpIds(newSelectedRowKeys);
  };

  const rowSelectionNoGroup = {
    selectedRowKeys: selectedGfpIds,
    onChange: onSelectChangeNoGroup,
    getCheckboxProps: (record: GfpCarts) => ({
      indeterminate:
        record.items.some(item => selectedGfpIds.includes(item.gfpId)) &&
        !record.items.every(item => selectedGfpIds.includes(item.gfpId))
    })
  };

  const cancelGfp = useCallback(
    (ids: number[]) => {
      const uniqueIds = Array.from(new Set(ids));
      const gfpCancel = new CancelGfp();
      setIsPageLoading(true);
      handlePromiseUseCase<number[], AxiosError>(
        gfpCancel.execute({ gfpIds: uniqueIds }),
        () => {
          setIsPageLoading(false);
          activeAlert({
            message: 'GFPs canceladas com sucesso!',
            type: 'success',
            timeout: 5000
          });
          form.submit();
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [setIsPageLoading, handlePromiseUseCase, activeAlert, form]
  );

  const getGfpResponse = useCallback(
    (ids: number[]) => {
      const gfpPrint = new GetGfpPrint();
      setIsPageLoading(true);
      handlePromiseUseCase<IGfp[], AxiosError>(
        gfpPrint.execute({ gfpIds: ids, isTagCart: false }),
        data => {
          setGfp(data);
          setShowGenerateGFP(true);
          setIsPageLoading(false);
          setTimeout(() => {
            handlePrintGFP();
            setShowGenerateGFP(false);
          }, 0);
          form.submit();
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, handlePromiseUseCase, handlePrintGFP, form]
  );

  const selectedGfps = gfp.filter(item => selectedGfpIds.includes(item.gfpId));

  const mappedGfpCarts = gfpCarts.map((gfps, index) => ({
    idGrouped: `isGrouped - ${index}`,
    idNoGrouped: `isNoGrouped - ${index}`,
    ...gfp
  }));

  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
  // const handleTableChange = useCallback(
  //   async (paginationGfp: TablePaginationConfig) => {
  //     try {
  //       await formFilterInstance?.validateFields?.();

  //       setDataStore(state => {
  //         state.table.data = [];
  //         state.table.tableParams.pagination.current = paginationGfp.current;
  //         state.table.tableParams.pagination.pageSize = paginationGfp.pageSize;
  //       });

  //       setPagination({
  //         pageSize: paginationGfp.pageSize || 100,
  //         current: paginationGfp.current || 1,
  //         pageNumber: paginationGfp.current || 1
  //       });

  //       const newData = await handleSearch();

  //       setDataStore(state => {
  //         state.table.data = newData;
  //       });

  //       const newSelectedGfpIds = new Set(selectedGfpIds);
  //       newData.forEach(cart => {
  //         cart.items.forEach(item => {
  //           if (selectedGfpIds.includes(item.gfpId)) {
  //             newSelectedGfpIds.add(item.gfpId);
  //           }
  //         });
  //       });

  //       setSelectedGfpIds(Array.from(newSelectedGfpIds));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  //   [
  //     formFilterInstance,
  //     setDataStore,
  //     setPagination,
  //     handleSearch,
  //     selectedGfpIds
  //   ]
  // );

  return (
    <>
      <ModalDefault
        width={500}
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <ModalHeader style={{ marginBottom: '5px' }}>
          <span>Observação GFP</span>
        </ModalHeader>
        <ModalBody style={{ marginTop: '5px', marginBottom: '5px' }}>
          <TextArea rows={4} disabled value={currentObservation} />
        </ModalBody>
        <ModalFooter
          style={{
            marginTop: '5px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalDefault>

      <FrameStyled defaultActiveKey="table" ghost accordion>
        <FrameStyled.Panel
          header={
            <DividerFrame orientation="left">
              {t('gfp.table.managementDivider')}
            </DividerFrame>
          }
          key="table"
          extra={panelFilters()}
        >
          <CardContainer>
            <FlexContent>
              <StyledText>
                {selectedGfpIds.length} itens selecionados
              </StyledText>
              <Row gutter={10}>
                <Col>
                  <ButtonPrinter
                    text="Imprimir GFPs"
                    disabled={selectedGfpIds.length === 0}
                    onClick={() => getGfpResponse(selectedGfpIds as number[])}
                  />
                </Col>
              </Row>
            </FlexContent>
          </CardContainer>

          <CardContainer>
            <Form>
              {!isGrouped ? (
                <StyledTable<any>
                  key="idNoGrouped"
                  columns={viewGfps}
                  dataSource={gfpCarts.flatMap(cart =>
                    cart.items?.map(item => ({
                      ...item,
                      ...cart,
                      cartNumber: cart.cartNumber,
                      cce: cart.cce,
                      type: cart.type
                    }))
                  )}
                  pagination={false}
                  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
                  // pagination={{
                  //   current: pagination.current,
                  //   pageSize: pagination.pageSize,
                  //   total: gfpCartsPagination.total,
                  //   showSizeChanger: true
                  // }}
                  // onChange={(page: TablePaginationConfig) => {
                  //   handleTableChange(page);
                  // }}
                  loading={isLoading}
                  rowKey="gfpId"
                  rowSelection={rowSelectionNoGroup}
                  rowClassName={rowClassName}
                  scroll={{ y: 500 }}
                />
              ) : (
                <StyledTable<any>
                  key="idGrouped"
                  columns={viewCarts}
                  dataSource={gfpCarts}
                  expandable={{ expandedRowRender, expandedRowKeys }}
                  expandIcon={() => null}
                  pagination={false}
                  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
                  // pagination={{
                  //   current: pagination.current,
                  //   pageSize: pagination.pageSize,
                  //   total: gfpCartsPagination.total,
                  //   showSizeChanger: true
                  // }}
                  // onChange={(page: TablePaginationConfig) => {
                  //   handleTableChange(page);
                  // }}
                  loading={isLoading}
                  rowKey="cartNumber"
                  rowSelection={rowSelection}
                  rowClassName={rowClassName}
                />
              )}
            </Form>
          </CardContainer>
        </FrameStyled.Panel>
      </FrameStyled>
      <Subtitles style={{ fontSize: 13, marginTop: 20 }}>
        <b>Legenda:</b>
        <HistoryOutlined style={{ color: '#FAAD14' }} />
        <span>Aberta</span>

        <img src={Partial} alt="partial" style={{ width: '13px' }} />
        <span>Recepcionada</span>

        <CheckCircleOutlined style={{ color: '#52C41A' }} />
        <span>Fechada</span>

        <StopOutlined style={{ color: '#FF0000' }} />
        <span>Cancelado</span>
      </Subtitles>
      {showGenerateGFP && (
        <PrintContainer>
          <GenerateGFP
            gfp={selectedGfps}
            show={showGenerateGFP}
            ref={printRef}
            setShowGenerateGFP={setShowGenerateGFP}
            selectedRowKeys={selectedRowKeys}
          />
        </PrintContainer>
      )}
      <ConfirmModal
        confirmIcon={
          <ExclamationCircleOutlined
            style={{ color: '#F78E1E', fontSize: 25 }}
          />
        }
        confirmTitle="Cancelar GFPs"
        onOk={() => {
          cancelGfp(selectedGfpIds as number[]);
          setModalConfirmationIsVisible(false);
          resetSelection();
        }}
        onCancel={() => {
          setModalConfirmationIsVisible(false);
          resetSelection();
        }}
        question="Tem certeza que deseja cancelar GFPs?"
        visible={modalConfirmationIsVisible}
        cancelText="Cancelar"
        okText="Sim, cancelar"
      />
    </>
  );
}
