import { create } from 'zustand';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface NonConformityState {
  nonConformities: NonConformity[];
  nonConformityPagination: TablePagination;
  isLoadingNonConformity: boolean;
  handleNonConformityPagination: (newPagination: TablePagination) => void;
  addNonConformities: (nonConformitiesValues: NonConformity[]) => void;
  setIsLoadingNonConformity: () => void;
}

const useNonConformityStore = create<NonConformityState>()(set => ({
  systems: [],
  nonConformities: [],
  nonConformityPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingNonConformity: false,
  handleNonConformityPagination: newPagination =>
    set({ nonConformityPagination: newPagination }),
  addNonConformities: nonConformityValues =>
    set({ nonConformities: nonConformityValues }),
  setIsLoadingNonConformity: () => {
    set(state => ({ isLoadingNonConformity: !state.isLoadingNonConformity }));
  }
}));

export default useNonConformityStore;
