import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import VisionCount from '~/@core/domain/model/rfidReports/VisionCount';
import { ReceiptService } from '~/@core/infrastructure/api/services/rfidReports';
export default class GetVisionsCount
  implements IUseCase<FilterData, Promise<DefaultPaginated<VisionCount[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<VisionCount[]>> {
    return ReceiptService.fetchVisionsCount(filter);
  }
}
