import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import GetFrequencies from '~/@core/application/useCases/rfidReports/inventory/getFrequencies';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import Frequency from '~/@core/domain/model/rfidReports/Frequency';
import FormFilterDataModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterDataModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useFrequencyStore from '~/zustand/rfidReports/inventory/frequency-state';
import { SearchButtonDiv } from '../Dones/FormFilterInventoryDone.styled';

interface Props {
  form: FormInstance<FormFilterDataModel>;
}
export default function FormFilterInventoryFrequency(props: Props) {
  const { form } = props;
  const {
    frequencyPagination,
    addFrequencies,
    handleFrequencyPagination,
    setIsLoadingFrequency
  } = useFrequencyStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    form.setFieldsValue({
      dtaFilter: []
    });
  }, [form]);

  const onSubmitFrequency = useCallback(
    (formValues: FormFilterDataModel) => {
      const filterFrequency = MapFormToFilter.toFilter(formValues, {
        pageSize: frequencyPagination.pageSize,
        pageNumber: frequencyPagination.current
      });

      const getFrequencies = new GetFrequencies();
      setIsLoadingFrequency();

      handlePromiseUseCase<DefaultPaginated<Frequency[]>, AxiosError>(
        getFrequencies.execute(filterFrequency),
        frequencyOut => {
          addFrequencies(frequencyOut.results);
          handleFrequencyPagination({
            ...frequencyPagination,
            total: frequencyOut.totalCount
          });
          setIsLoadingFrequency();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingFrequency();
        }
      );
    },
    [
      frequencyPagination,
      setIsLoadingFrequency,
      handlePromiseUseCase,
      addFrequencies,
      handleFrequencyPagination,
      activeAlert
    ]
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSubmitFrequency}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item name="dtaFilter" label="Data de Consulta: ">
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Início', 'Fim']}
            />
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {}}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
