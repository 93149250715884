/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileAddOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import {
  Badge,
  Form,
  FormInstance,
  message,
  Select,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Tooltip
} from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import GetProductPacking from '~/@core/application/useCases/inputs/product/getProductPacking';
import GetQualityObservations from '~/@core/application/useCases/order/getQualityObservations';
import FilterPackings from '~/@core/domain/filters/packing/FilterPacking';
import Order from '~/@core/domain/model/order/Order';
import QualityObservations from '~/@core/domain/model/order/QualityObservations';
import Packing from '~/@core/domain/model/packing/Packing';
import { DividerFrame } from '~/components/Globals';
import { DebouncedNumberInput } from '~/components/atoms/DebounceNumberInput/DebounceNumberInput';
import { DebouncedInput } from '~/components/atoms/DebouncedInput';
import PanelFilters from '~/components/atoms/Inputs/PanelFilters';
import { QualityContainer } from '~/components/atoms/QualityContainer';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { changeFocusWithRef } from '~/helpers/util/table';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import {
  CheckboxContainer,
  FrameStyled,
  GroupTitleInformation,
  SpanLabel,
  StyledTable,
  StyledText
} from './style';

const getBackgroundColor = (
  isDisabled: boolean,
  isSelected: boolean
): string => {
  if (isDisabled) {
    return '#D3D3D3';
  }
  if (isSelected) {
    return '#52C41A';
  }
  return '#F78E1E';
};

interface Props {
  form: FormInstance<any>;
}

export default function TableListOrders(props: Props) {
  const { form } = props;
  const { t } = useTranslation();

  const {
    showAddGFPModal,
    setShowAddGFPModal,
    setItemsCount,
    order,
    setOrder,
    orderPagination,
    orderSelected,
    setOrderSelected,
    orderTempSelected,
    setOrderTempSelected,
    setQualityObservations,
    qualityObservations
  } = useContext(GFPContext);

  const {
    filters,
    pagination,
    setPagination,
    setDataOrders,
    handleSearchOrders,
    setDataStore,
    formFilterInstance,
    tableParams,
    isLoading,
    dataSource
  } = useGfpStore(
    useShallow(state => ({
      filters: state.filters,
      pagination: state.pagination,
      setPagination: state.setPagination,
      setDataOrders: state.setDataOrders,
      selectedGfpIds: state.selectedGfpIds,
      setSelectedGfpIds: state.setSelectedGfpIds,
      handleSearchOrders: state.handleSearchOrders,
      setDataStore: state.setData,
      formFilterInstance: state.formFilterInstance,
      tableParams: state.tableParams,
      isLoading: state.tableOrders.isLoading,
      dataSource: state.tableOrders.data
    }))
  );

  const { handlePromiseUseCase, activeAlert, siteIdSelected } =
    useContext(globalContext);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [expandedSelections, setExpandedSelections] = useState<
    Record<number, React.Key[]>
  >({});
  const [packings, setPackings] = useState<Packing[]>([]);
  const [searchOrders, setSearchOrders] = useState<Order[]>([]);
  const [clickedOrderIds, setClickedOrderIds] = useState<number[]>([]);
  const [isGrouped, setIsGrouped] = useState<boolean>(false);
  const [hasMulti, setHasMulti] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  const groupByOrderNumber = (orders: Order[]) => {
    const groupedOrders: { [key: number]: Order[] } = {};

    orders.forEach(orderGrouped => {
      if (!groupedOrders[orderGrouped.orderNumber]) {
        groupedOrders[orderGrouped.orderNumber] = [];
      }
      groupedOrders[orderGrouped.orderNumber].push(orderGrouped);
    });

    return Object.values(groupedOrders).map(group => ({
      key: group[0].key !== undefined ? group[0].key : group[0].orderNumber,
      orderNumber: group[0].orderNumber,
      customerName: group[0].customerName,
      customerAccount: group[0].customerAccount,
      deliveryDate: group[0].deliveryDate,
      commercialDate: group[0].commercialDate,
      origem: group[0].origem,
      orders: group
    }));
  };

  const groupedOrders = useMemo(() => groupByOrderNumber(order), [order]);

  const focusControlledColumns = useMemo(() => {
    return ['quantityPerPacking'];
  }, []);

  useEffect(() => {
    setSearchOrders(order);
  }, [order]);

  useEffect(() => {
    setItemsCount(selectedRowKeys.length);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (order) {
      const getQualityObservations = new GetQualityObservations();

      handlePromiseUseCase<QualityObservations[], AxiosError>(
        getQualityObservations.execute(),
        (qualityObsOut: any) => {
          setQualityObservations(
            qualityObsOut.result.sort((a: any, b: any) => a.code - b.code)
          );
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!showAddGFPModal) {
      setSelectedRowKeys([]);
      setHasMulti(false);
    }
  }, [showAddGFPModal]);

  const fetchPackings = useCallback(
    (filter: FilterPackings) => {
      setPackings([]);
      const getProductPacking = new GetProductPacking();
      handlePromiseUseCase<Packing[], AxiosError>(
        getProductPacking.execute(filter),
        packingsOut => {
          setPackings(packingsOut);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, handlePromiseUseCase]
  );

  const searchHere = useCallback((objects, value) => {
    const orderFounded: Order[] = [];
    const orderIdFounded: any = {};

    objects.forEach((objeto: any) => {
      let found = false;
      Object.keys(objeto).forEach(property => {
        if (
          !found &&
          objeto[property] &&
          objeto[property]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          if (!orderIdFounded[objeto.orderId]) {
            orderFounded.push(objeto);
            orderIdFounded[objeto.orderId] = true;
          }
          found = true;
        }
      });
    });

    return orderFounded;
  }, []);

  const resetSelection = () => {
    setSearchOrders([]);
    setOrderSelected([]);
    setSelectedRowKeys([]);
    setExpandedSelections([]);
  };

  const panelFilters = () => (
    <Form>
      <PanelFilters
        searchFormName="searchFormName"
        setSearchValue={setSearchValue}
        handleFunction={() => {
          const searched = searchHere(order, searchValue);
          setSearchOrders(searched);
        }}
        switchLabel={t('gfp.table.switchLabel')}
        switchFormName="GroupOrders"
        form={form}
        isRadioEnabled
        isGroupByOrders={isGrouped}
        setIsGroupByOrders={(
          value: boolean | ((prevState: boolean) => boolean)
        ) => {
          setIsGrouped(typeof value === 'function' ? value(isGrouped) : value);
          resetSelection();
        }}
      />
    </Form>
  );

  const viewOrders: TableColumnsType<Order> = [
    {
      key: 'orderId',
      title: () => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>Pedido</StyledText>
            <SpanLabel>Origem</SpanLabel>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'orderNumber',
      width: 65,
      render: (value: any, record: Order) => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>{record.orderNumber}</StyledText>
            <SpanLabel>{record.origem}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'deliveryDate',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Data Entrega</StyledText>
            <SpanLabel>Horário</SpanLabel>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'deliveryDate',
      align: 'center',
      width: 100,
      sorter: {
        compare: (a: Order, b: Order) =>
          a.deliveryDate.localeCompare(b.deliveryDate),
        multiple: 3
      },
      render: (value: any, record: Order) => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>
              {moment(record.deliveryDate).format(`DD/MM/YYYY`)}
            </StyledText>
            <SpanLabel>{record.deliveryDateTime}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'commercialDate',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Data</StyledText>
            <StyledText>Comercial</StyledText>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'commercialDate',
      align: 'center',
      width: 90,
      render: (commercialDate: Date) => {
        return moment(commercialDate).format(`DD/MM/YYYY`);
      }
    },
    {
      key: 'customerName',
      title: 'Cliente',
      dataIndex: 'customerName',
      width: 65,
      sorter: {
        compare: (a: Order, b: Order) =>
          Number(a.customerAccount) - Number(b.customerAccount),
        multiple: 2
      },
      render: (value: any, record: Order) => {
        return (
          <Tooltip title={record.customerName}>
            {record.customerAccount}
          </Tooltip>
        );
      }
    },
    {
      key: 'productDescription',
      title: 'Descrição do Produto',
      dataIndex: 'productDescription',
      width: 190,
      sorter: {
        compare: (a: Order, b: Order) =>
          a.productDescription.localeCompare(b.productDescription),
        multiple: 1
      },
      render: (value: any, record: Order) => {
        return (
          <GroupTitleInformation>
            <Tooltip title={record.productDescription}>
              <span>
                {record.productDescription.length > 25
                  ? `${record.productDescription?.substring(0, 25)}...`
                  : record.productDescription}
              </span>
            </Tooltip>
            <SpanLabel>{record.productCode}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'quality',
      title: 'Qld.',
      dataIndex: 'quality',
      align: 'center',
      width: 40,
      render: (value: any) => {
        return <QualityContainer quality={value}>{value}</QualityContainer>;
      }
    },
    {
      key: 'packingCompositionId',
      title: 'Emb.',
      width: 90,
      render: (_: any, record: Order, index: number) => {
        return (
          <Select
            placeholder="Selecione..."
            disabled={selectedRowKeys.includes(record.orderId)}
            dropdownStyle={{ minWidth: '200px' }}
            style={{ width: '80px' }}
            defaultValue={`${record.packingCode} - ${record.packingDescription}`}
            filterOption
            showSearch
            onChange={value => {
              handleChangeOrderColumn(
                value ?? '',
                'packingCode',
                record.orderId
              );
              const packingSelected = packings.find(
                pack => `${pack.code} - ${pack.description}` === value
              );
              handleChangeOrderColumn(
                packingSelected?.id ?? '',
                'packingId',
                record.orderId
              );
            }}
            onClick={() => {
              const filterProduct = {
                siteId: siteIdSelected,
                productId: record.productId
              };
              fetchPackings(filterProduct);
            }}
          >
            {packings &&
              packings.map(packing => (
                <Select.Option
                  key={packing.id}
                  value={`${packing.code} - ${packing.description}`}
                >
                  {packing.code} - {packing.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'quantityPacking',
      title: () => {
        return (
          <>
            <StyledText>QE x QpE</StyledText>
            <SpanLabel>(Restante)</SpanLabel>
          </>
        );
      },
      dataIndex: 'quantityPacking',
      align: 'center',
      width: 80,
      // #FIXME
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      render: (_: any, record: Order, index: number) => {
        return (
          <>{`${record.recommendedQuantityPacking} x ${record.quantityPerPacking}`}</>
        );
      }
    },
    {
      key: 'quantityPacking',
      title: 'QE',
      dataIndex: 'quantityPacking',
      align: 'center',
      width: 70,
      render: (quantityPacking: string, record: Order, index: number) => {
        const inputId = `idToFocus-${focusControlledColumns.indexOf(
          'quantityPacking'
        )}-${record.orderId}`;
        return (
          <DebouncedNumberInput
            max={record.recommendedQuantityPacking}
            id={inputId}
            onKeyDown={(e: any) =>
              changeFocusWithRef(
                e,
                focusControlledColumns.indexOf('quantityPacking'),
                index,
                tableRef
              )
            }
            value={quantityPacking}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={value => {
              handleChangeOrderColumn(
                value ?? '',
                'quantityPacking',
                record.orderId
              );
            }}
            style={{ width: '50px', textAlign: 'center' }}
            disabled={selectedRowKeys.includes(record.orderId)}
          />
        );
      }
    },
    {
      key: 'multi',
      dataIndex: 'multi',
      title: 'Mult.',
      align: 'center',
      width: 70,
      render: (multi: string, record: Order, index: number) => {
        const inputId = `idToFocus-${focusControlledColumns.indexOf(
          'multi'
        )}-${index}`;
        return (
          <DebouncedInput
            id={inputId}
            onKeyDown={(e: any) =>
              changeFocusWithRef(
                e,
                focusControlledColumns.indexOf('multi'),
                index,
                tableRef
              )
            }
            value={multi}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={value => {
              handleChangeOrderColumn(value ?? '', 'multi', record.orderId);
            }}
            style={{
              width: '50px',
              textAlign: 'center',
              wordWrap: 'break-word',
              wordBreak: 'break-word'
            }}
            disabled={selectedRowKeys.includes(record.orderId)}
          />
        );
      }
    },
    {
      key: 'unitPrice',
      title: 'Valor Unit.',
      dataIndex: 'unitPrice',
      align: 'center',
      width: 80,
      render: (value: number) => {
        return value?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        });
      }
    },
    {
      key: 'observationId1',
      title: 'Obs 1.',
      dataIndex: 'observation',
      width: 90,
      render: (text: any, record: Order, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '220px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{
              maxWidth: 80,
              width: 80,
              wordWrap: 'break-word',
              wordBreak: 'break-word'
            }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeOrderColumn(
                value.value ?? '',
                'observationId1',
                record.orderId
              );
            }}
            disabled={selectedRowKeys.includes(record.orderId)}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observationId2',
      title: 'Obs 2.',
      dataIndex: 'observation',
      width: 90,
      render: (text: any, record: Order, index: number) => {
        return (
          <Select
            dropdownStyle={{ minWidth: '220px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{
              maxWidth: 80,
              width: 80,
              wordWrap: 'break-word',
              wordBreak: 'break-word'
            }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeOrderColumn(
                value.value ?? '',
                'observationId2',
                record.orderId
              );
            }}
            disabled={selectedRowKeys.includes(record.orderId)}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code} - ${observation.description}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observation',
      dataIndex: 'observation',
      title: 'Obs.',
      width: 130,
      render: (text: string) => {
        let displayText = text;
        if (text && text.length > 15) {
          displayText = `${text.substring(0, 15)}...`;
        }
        // eslint-disable-next-line no-control-regex
        const hasSpecialCharacters = /[^\x00-\x7F]/.test(displayText);
        if (hasSpecialCharacters) {
          displayText = JSON.stringify(displayText);
        }
        return (
          <Tooltip title={text}>
            <span>{displayText}</span>
          </Tooltip>
        );
      }
    },
    Table.SELECTION_COLUMN
  ];

  const toggleRowSelection = (orderId: number, isGroupedTable: boolean) => {
    setSelectedRowKeys(prevSelected => {
      if (prevSelected.includes(orderId)) {
        return prevSelected.filter(key => key !== orderId);
      }
      return [...prevSelected, orderId];
    });

    if (isGroupedTable) {
      setExpandedSelections(prev => {
        const updatedSelections = { ...prev };
        Object.keys(updatedSelections).forEach(key => {
          const numericKey = key as unknown as number;
          updatedSelections[numericKey] = updatedSelections[numericKey].filter(
            keys => keys !== orderId
          );
        });
        return updatedSelections;
      });
    }
  };

  const handleRowSelectionChange = (
    expandedKey: number,
    selectedKeys: React.Key[],
    selectedRows: any[] = [],
    record: Order
  ) => {
    const updatedSelectedKeys = [...selectedRowKeys];

    const anyMulti = selectedRows.some((row: any) => row.multi);
    setHasMulti(anyMulti);

    setExpandedSelections(prev => ({
      ...prev,
      [expandedKey]: updatedSelectedKeys
    }));

    setOrderSelected(prevOrder => {
      const orderIds = selectedRows.map(row => row.orderId);
      const updatedOrder = prevOrder.filter(
        it => !orderIds.includes(it.orderId)
      );
      const newOrders = selectedRows.filter(
        row => !prevOrder.some(it => it.orderId === row.orderId)
      );
      return [...updatedOrder, ...newOrders];
    });
  };

  const handleOpenMultiGfpModal = () => {
    resetSelection();
    setShowAddGFPModal(true);
  };

  const expandedRowRender = (record: Order) => {
    const expandedRowSelection = {
      selectedRowKeys: expandedSelections[record.orderNumber],
      onChange: (selectedKeys: React.Key[], selectedRows: any[]) => {
        handleRowSelectionChange(
          record.orderNumber,
          selectedKeys,
          selectedRows,
          record
        );
      },
      hideSelectAll: true,
      renderCell: (checked: boolean, items: any, index: number, node: any) => {
        const isQuantityPackingEmpty = !items.quantityPacking;

        const anySelectedRowHasMulti = orderSelected.some(row => row.multi);
        const anySelectedRowWithoutMulti = orderSelected.some(
          row => !row.multi
        );

        let isDisabled = false;

        if (orderSelected.length > 0) {
          if (anySelectedRowHasMulti && !items.multi) {
            isDisabled = true;
          } else if (anySelectedRowWithoutMulti && items.multi) {
            isDisabled = true;
          }
        }

        if (isQuantityPackingEmpty) {
          isDisabled = true;
        }

        const isSelected = orderSelected.some(
          it => it.orderId === items.orderId
        );

        const backgroundColor = getBackgroundColor(isDisabled, isSelected);

        return (
          <Badge
            count={checked && items.multi ? 0 : badgePosition(items.orderId)}
          >
            <CheckboxContainer
              style={{
                backgroundColor,
                opacity: isQuantityPackingEmpty ? 0.5 : 1,
                pointerEvents: isDisabled ? 'none' : 'auto'
              }}
              onClick={() => toggleRowSelection(items.orderId, isGrouped)}
            >
              {items.multi ? (
                <FileAddOutlined
                  onClick={handleOpenMultiGfpModal}
                  style={{ fontSize: '16px', color: 'white' }}
                />
              ) : (
                <ShoppingCartOutlined
                  style={{ fontSize: '16px', color: 'white' }}
                />
              )}
              {node}
            </CheckboxContainer>
          </Badge>
        );
      }
    };

    const expandedTable = [
      {
        key: 'productDescription',
        title: 'Descrição do Produto',
        dataIndex: 'productDescription',
        width: 400,
        render: (value: any, item: Order) => {
          return (
            <>
              <div>{item.productDescription}</div>
              <div>{item.productCode}</div>
            </>
          );
        }
      },
      {
        key: 'quality',
        title: 'Qld.',
        dataIndex: 'quality',
        align: 'center',
        width: 10,
        render: (value: any) => {
          return <QualityContainer quality={value}>{value}</QualityContainer>;
        }
      },
      {
        key: 'packingCompositionId',
        title: 'Emb.',
        width: 120,
        render: (value: any, item: any, index: number) => {
          return (
            <Select
              placeholder="Selecione..."
              disabled={selectedRowKeys.includes(item.orderId)}
              style={{ width: '100px' }}
              defaultValue={`${item.packingCode} - ${item.packingDescription}`}
              filterOption
              showSearch
              onChange={values => {
                handleChangeOrderColumn(
                  values ?? '',
                  'packingCode',
                  item.orderId
                );
                /* handleChangeOrderColumn(
                  values ?? '',
                  'packingId',
                  item.orderId
                ); */
              }}
              onClick={() => {
                const filterProduct = {
                  siteId: siteIdSelected,
                  productId: item.productId
                };
                fetchPackings(filterProduct);
              }}
            >
              {packings &&
                packings.map(packing => (
                  <Select.Option
                    key={packing.id}
                    value={`${packing.code} - ${packing.description}`}
                  >
                    {packing.code} - {packing.description}
                  </Select.Option>
                ))}
            </Select>
          );
        }
      },
      {
        key: 'quantityPacking',
        title: 'QE x QpE',
        dataIndex: 'quantityPacking',
        align: 'center',
        width: 30,
        render: (_text: string, item: any) => (
          <>{`${item.recommendedQuantityPacking} x ${item.quantityPerPacking}`}</>
        )
      },
      {
        key: 'quantityPacking',
        title: 'QE',
        dataIndex: 'quantityPacking',
        align: 'center',
        width: 70,
        render: (
          quantityPacking: string,
          orderChanged: Order,
          index: number
        ) => {
          const inputId = `idToFocus-${focusControlledColumns.indexOf(
            'quantityPacking'
          )}-${index}`;
          return (
            <DebouncedNumberInput
              max={orderChanged.recommendedQuantityPacking}
              id={inputId}
              onKeyDown={(e: any) =>
                changeFocusWithRef(
                  e,
                  focusControlledColumns.indexOf('quantityPacking'),
                  index,
                  tableRef
                )
              }
              value={quantityPacking}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={value => {
                handleChangeOrderColumn(
                  value ?? '',
                  'quantityPacking',
                  orderChanged.orderId
                );
              }}
              style={{ width: '50px', textAlign: 'center' }}
              disabled={selectedRowKeys.includes(orderChanged.orderId)}
            />
          );
        }
      },
      {
        key: 'multi',
        dataIndex: 'multi',
        title: 'Mult.',
        align: 'center',
        width: 30,
        render: (multi: string, orderChanged: Order, index: number) => {
          const inputId = `idToFocus-${orderChanged.orderId}--${orderChanged.key}`;

          return (
            <DebouncedInput
              id={inputId}
              onKeyDown={(e: any) =>
                changeFocusWithRef(
                  e,
                  focusControlledColumns.indexOf('multi'),
                  index,
                  tableRef
                )
              }
              value={multi}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={value => {
                handleChangeOrderColumn(
                  value ?? '',
                  'multi',
                  orderChanged.orderId
                );
              }}
              style={{
                width: '50px',
                textAlign: 'center',
                wordWrap: 'break-word',
                wordBreak: 'break-word'
              }}
              disabled={selectedRowKeys.includes(orderChanged.orderId)}
            />
          );
        }
      },
      {
        key: 'observationId1',
        title: 'Obs 1.',
        dataIndex: 'observation',
        width: 50,
        render: (text: any, item: Order, index: number) => {
          return (
            <Select
              dropdownStyle={{ minWidth: '220px' }}
              filterOption
              optionFilterProp="children"
              placeholder={t('generals.selectPlaceholder')}
              style={{
                maxWidth: 90,
                width: 90,
                wordWrap: 'break-word',
                wordBreak: 'break-word'
              }}
              showSearch
              allowClear
              labelInValue
              optionLabelProp="label"
              onChange={(value: any) => {
                handleChangeOrderColumn(
                  value.value ?? '',
                  'observationId2',
                  item.orderId
                );
              }}
              disabled={selectedRowKeys.includes(item.orderId)}
            >
              {qualityObservations &&
                qualityObservations.map(observation => (
                  <Select.Option
                    key={observation.id}
                    value={observation.id}
                    label={`${observation.code} - ${observation.description}`}
                  >
                    {observation.code} - {observation.description}
                  </Select.Option>
                ))}
            </Select>
          );
        }
      },
      {
        key: 'observationId2',
        title: 'Obs 2.',
        dataIndex: 'observation',
        width: 50,
        render: (text: any, item: Order, index: number) => {
          return (
            <Select
              dropdownStyle={{ minWidth: '220px' }}
              filterOption
              optionFilterProp="children"
              placeholder={t('generals.selectPlaceholder')}
              style={{
                maxWidth: 90,
                width: 90,
                wordWrap: 'break-word',
                wordBreak: 'break-word'
              }}
              showSearch
              allowClear
              labelInValue
              optionLabelProp="label"
              onChange={(value: any) => {
                handleChangeOrderColumn(
                  value.value ?? '',
                  'observationId2',
                  item.orderId
                );
              }}
              disabled={selectedRowKeys.includes(item.orderId)}
            >
              {qualityObservations &&
                qualityObservations.map(observation => (
                  <Select.Option
                    key={observation.id}
                    value={observation.id}
                    label={`${observation.code} - ${observation.description}`}
                  >
                    {observation.code} - {observation.description}
                  </Select.Option>
                ))}
            </Select>
          );
        }
      },
      {
        key: 'observation',
        dataIndex: 'observation',
        title: 'Obs.',
        width: 50,
        render: (text: string) => {
          let displayText = text;
          if (text && text.length > 15) {
            displayText = `${text.substring(0, 15)}...`;
          }
          // eslint-disable-next-line no-control-regex
          const hasSpecialCharacters = /[^\x00-\x7F]/.test(displayText);
          if (hasSpecialCharacters) {
            displayText = JSON.stringify(displayText);
          }
          return (
            <Tooltip title={text}>
              <span>{displayText}</span>
            </Tooltip>
          );
        }
      },
      Table.SELECTION_COLUMN
    ];

    return (
      <Table
        columns={expandedTable}
        dataSource={record.orders.map((ordersSelected: any, index: number) => {
          return {
            ...ordersSelected,
            key: `${ordersSelected.orderNumber}-${ordersSelected.orderId}`
          };
        })}
        pagination={false}
        rowKey="key"
        rowSelection={expandedRowSelection}
      />
    );
  };

  useEffect(() => {
    if (!showAddGFPModal) {
      resetSelection();
    }
  }, [showAddGFPModal]);

  const groupedColumn: TableColumnsType<Order> = [
    {
      key: 'orderId',
      title: () => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>Pedido</StyledText>
            <SpanLabel>Origem</SpanLabel>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'orderNumber',
      width: 65,
      render: (value: any, record: Order) => {
        return (
          <GroupTitleInformation style={{ alignItems: 'center' }}>
            <StyledText>{record.orderNumber}</StyledText>
            <SpanLabel>{record.origem}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'customerName',
      title: 'Cliente',
      dataIndex: 'customerName',
      width: 200,
      render: (_value, record) => {
        return (
          <Tooltip title={record.customerName} placement="topLeft">
            <StyledText>{record.customerAccount}</StyledText>
          </Tooltip>
        );
      }
    },
    {
      key: 'deliveryDate',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Data Entrega</StyledText>
            <div
              style={{
                color: '#8c8c8c',
                fontSize: '12px',
                fontWeight: 'normal'
              }}
            >
              Horário
            </div>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'deliveryDate',
      width: 20,
      render: (value: any, record) => {
        return (
          <GroupTitleInformation>
            <StyledText>
              {moment(record.deliveryDate).format(`DD/MM/YYYY`)}
            </StyledText>
            <SpanLabel>{record.deliveryDateTime}</SpanLabel>
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'commercialDate',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Data Comercial</StyledText>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'commercialDate',
      width: 20,
      render: (commercialDate: Date) => {
        return moment(commercialDate).format(`DD/MM/YYYY`);
      }
    }
  ];

  const handleChangeOrderColumn = useCallback(
    (valueParam: any, property: string, orderId: number) => {
      let value: string | number = 0;
      if (typeof valueParam === 'string') {
        value = valueParam;
      }
      if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
        value = valueParam;
      }
      setOrder(prevOrder => {
        const newOrderTableChanged = prevOrder.map(orderItem => {
          if (orderItem.orderId === orderId) {
            return {
              ...orderItem,
              [property]: value,
              changed: true
            };
          }
          return orderItem;
        });

        return newOrderTableChanged;
      });
    },
    [order, setOrder]
  );

  const onSelectChange = (
    newSelectedOrdersRowKeys: React.Key[],
    selectedRows: any
  ) => {
    const anyMulti = selectedRows.some((row: any) => row.multi);

    if (anyMulti) handleOpenMultiGfpModal();

    const updatedOrderTempSelected = [...orderTempSelected];

    for (let i = 0; i < selectedRows.length; i++) {
      const isOrderSelected = orderSelected.find(
        (obj: any) => obj.orderId === selectedRows[i].orderId
      );

      if (!isOrderSelected) {
        if (selectedRows[i].multi === 0) {
          setOrderTempSelected([
            ...orderTempSelected,
            { key: i + 1, ...selectedRows[i] }
          ]);
        } else {
          updatedOrderTempSelected.length = 0;
          updatedOrderTempSelected.push({ key: 1, ...selectedRows[i] });
        }
      }
      setOrderSelected(selectedRows);
    }
    setOrderTempSelected(updatedOrderTempSelected);
    setOrderSelected(selectedRows);
    setHasMulti(anyMulti);
    setSelectedRowKeys(newSelectedOrdersRowKeys);
  };

  const badgePosition = (orderId: number) => {
    const position = selectedRowKeys.indexOf(orderId) + 1;
    return position;
  };

  const rowSelection = {
    columnTitle: 'Ações',
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    renderCell: (checked: boolean, record: any, index: number, node: any) => {
      const isClicked = clickedOrderIds === record.orderId;
      const isQuantityPackingEmpty = !record.quantityPacking;
      const isMultiFilled = record.multi;

      const hasUnfilledMultiSelected = selectedRowKeys.some(key => {
        const selectedRecord = searchOrders.find(
          orders => orders.orderId === key
        );
        return selectedRecord && !selectedRecord.multi;
      });

      const isDisabled =
        isQuantityPackingEmpty ||
        (hasMulti && !isMultiFilled) ||
        (hasUnfilledMultiSelected && isMultiFilled);

      const disabledBackgroundColor = '#D3D3D3';

      const backgroundColor = isDisabled
        ? disabledBackgroundColor
        : (checked && '#52C41A') || '#F78E1E';

      return (
        <Badge
          count={checked && record.multi ? 0 : badgePosition(record.orderId)}
        >
          <CheckboxContainer
            style={{
              backgroundColor,
              opacity: isQuantityPackingEmpty ? 0.5 : 1,
              pointerEvents: isDisabled ? 'none' : 'auto'
            }}
          >
            {record.multi ? (
              <FileAddOutlined
                style={{
                  fontSize: '16px',
                  color: 'white'
                }}
              />
            ) : (
              <ShoppingCartOutlined
                style={{
                  fontSize: '16px',
                  color: 'white'
                }}
              />
            )}
            {node}
          </CheckboxContainer>
        </Badge>
      );
    }
  };

  const handleTableChange = useCallback(
    async (paginationGfp: TablePaginationConfig) => {
      try {
        await formFilterInstance?.validateFields?.();

        setDataOrders(state => ({
          ...state,
          tableOrders: {
            ...state.tableOrders,
            isLoading: true
          }
        }));

        setPagination({
          pageSize: paginationGfp.pageSize || 100,
          current: paginationGfp.current || 1,
          pageNumber: paginationGfp.current || 1
        });

        const newData = await handleSearchOrders();

        setDataOrders(state => ({
          ...state,
          tableOrders: {
            ...state.tableOrders,
            data: newData,
            isLoading: false
          }
        }));

        return newData;
      } catch (error) {
        message.error('Erro ao buscar os dados');
      }
    },
    [formFilterInstance, setDataOrders, setPagination, handleSearchOrders]
  );

  return (
    <FrameStyled defaultActiveKey="table" ghost accordion>
      <FrameStyled.Panel
        header={
          <DividerFrame orientation="left">
            {t('gfp.table.divider')}
          </DividerFrame>
        }
        key="table"
        extra={panelFilters()}
      >
        {!isGrouped ? (
          <>
            <StyledTable<any>
              key="isNoGrouped"
              bordered
              columns={viewOrders}
              dataSource={dataSource.length ? dataSource : searchOrders}
              rowKey="orderId"
              rowSelection={rowSelection}
              scroll={{ y: window.innerHeight }}
              loading={isLoading}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: orderPagination.total,
                showSizeChanger: true
              }}
              onChange={(page: TablePaginationConfig) => {
                handleTableChange(page);
              }}
            />
          </>
        ) : (
          <>
            <StyledTable<any>
              key="isGrouped"
              bordered
              columns={groupedColumn}
              dataSource={pagination.current === 1 ? groupedOrders : dataSource}
              rowKey="orderNumber"
              expandable={{ expandedRowRender, columnWidth: '10px' }}
              scroll={{ y: window.innerHeight }}
              loading={isLoading}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: orderPagination.total,
                showSizeChanger: true
              }}
              onChange={(page: TablePaginationConfig) => {
                handleTableChange(page);
              }}
            />
          </>
        )}
      </FrameStyled.Panel>
    </FrameStyled>
  );
}
