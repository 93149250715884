/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetOrders from '~/@core/application/useCases/order/getOrders';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import Order from '~/@core/domain/model/order/Order';
import FormFilterOrderModel, {
  MapFormToFilterOrder
} from '~/@core/domain/ui/forms/FormFilterOrderModel';
import ButtonClear from '~/components/atoms/ButtonClear';
import ButtonFilter from '~/components/atoms/ButtonFilter';
import ButtonSearch from '~/components/atoms/ButtonSearch';
import DeliveryDateSelect from '~/components/atoms/Inputs/DeliveryDateSelect';
import SitesSelect from '~/components/atoms/Inputs/SitesSelect';
import DefaultRow from '~/components/bosons/DefaultRow';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import HideFilters from './HideFilters';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
}

export default function FormFilterListOrders(props: Props) {
  const { form } = props;
  const { t } = useTranslation();

  const { setSiteIdSelected, handlePromiseUseCase, activeAlert } =
    useContext(globalContext);
  const {
    orderPagination,
    setOrderPagination,
    visibleRegister,
    setVisibleRegister,
    setOrder,
    setDeliveryDateSelected,
    isPageLoading,
    setIsPageLoading
  } = useContext(GFPContext);

  const { setFiltersOrder, pagination } = useGfpStore();

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const resetFieldsOnChangeSite = useCallback(() => {
    form.resetFields([
      'DeliveryDate',
      'CategoryId',
      'GroupId',
      'ProductId',
      'RecipientId',
      'ColorId',
      'ProductQualityId',
      'ExpeditionId',
      'CustomerId',
      'OrderNumber'
    ]);
  }, [form]);

  const handleChangeSite = useCallback(
    inputValue => {
      setSiteIdSelected(inputValue);
      resetFieldsOnChangeSite();
    },
    [resetFieldsOnChangeSite, setSiteIdSelected]
  );

  const handleClearSite = useCallback(() => {
    form.resetFields(['siteId']);
    setSiteIdSelected(Number.NaN);
    resetFieldsOnChangeSite();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCalendarChange = useCallback((value: any) => {
    setDeliveryDateSelected(value);
  }, []);

  const onSubmitFilter = useCallback(
    (formValues: FormFilterOrderModel) => {
      setFiltersOrder(formValues);
      const filterOrders = MapFormToFilterOrder.toFilter(formValues, {
        pageSize: pagination.pageSize,
        pageNumber: pagination.current
      });
      if (!visibleRegister) setVisibleRegister(!visibleRegister);
      const getOrders = new GetOrders();
      if (!isPageLoading) setIsPageLoading(true);
      handlePromiseUseCase<DefaultPaginated<Order[]>, AxiosError>(
        getOrders.execute(filterOrders),
        ordersOut => {
          setIsPageLoading(false);
          setOrder(
            ordersOut.results.sort((a, b) => {
              return (
                parseInt(a.customerAccount, 10) -
                parseInt(b.customerAccount, 10)
              );
            })
          );
          setOrderPagination({
            ...orderPagination,
            total: ordersOut.totalCount
          });
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [
      activeAlert,
      handlePromiseUseCase,
      isPageLoading,
      orderPagination,
      setFiltersOrder,
      setIsPageLoading,
      setOrder,
      setOrderPagination,
      setVisibleRegister,
      visibleRegister
    ]
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmitFilter}
      style={{ marginTop: '20px' }}
      initialValues={{ DeliveryDate: [moment(), moment()] }}
    >
      <DefaultRow>
        <Col lg={15}>
          <SitesSelect
            onChange={handleChangeSite}
            onClear={handleClearSite}
            form={form}
          />
        </Col>
        <Col lg={6}>
          <Form.Item
            name="DeliveryDate"
            label={t('gfp.filters.deliveryDateInput')}
            rules={[
              { required: true, message: 'Selecione a data de entrega.' }
            ]}
          >
            <DeliveryDateSelect
              onCalendarChange={value => handleCalendarChange(value)}
            />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonFilter onClick={() => setShowFilters(!showFilters)} />
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonSearch onClick={() => form.submit()} />
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonClear onClick={handleClearSite} />
        </Col>
        {showFilters ? <HideFilters form={form} /> : null}
      </DefaultRow>
    </Form>
  );
}
