import { Card } from 'antd';
import ServiceRequestsFilters from '~/components/molecules/ServiceRequests/ServiceRequestsFilters/ServiceRequestsFilters';
import ServiceRequestsHeader from '~/components/molecules/ServiceRequests/ServiceRequestsHeader';
import * as S from './ServiceRequests.styled';

export default function ServiceRequests() {
  return (
    <Card>
      <S.Container>
        <ServiceRequestsHeader />
        <ServiceRequestsFilters />
      </S.Container>
    </Card>
  );
}
