import { Col, Row } from 'antd';
import React from 'react';

import * as S from '~/components/molecules/Shared/Header/Header.styled';

export interface IProps {
  title: string;
  subtitle: string;
  additionalActions?: React.ReactNode[];
}

export default function Header({
  title,
  subtitle,
  additionalActions = []
}: IProps) {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </Col>
      <Row justify="end">
        {additionalActions.map((additionalAction, index) => (
          <Col key={`HeaderAdditionalActionCol${index}`}>
            {additionalAction}
          </Col>
        ))}
      </Row>
    </Row>
  );
}
