import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Area from '~/@core/domain/model/rfidReports/Area';
import Cart from '~/@core/domain/model/rfidReports/Cart';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import Vision from '~/@core/domain/model/rfidReports/Vision';
import { apiRfid } from '../../config';
import VisionCount from '~/@core/domain/model/rfidReports/VisionCount';

export const fetchVisions = (params: FilterData) => {
  return new Promise<DefaultPaginated<Vision[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Vision[]>>>(
        'api/receipt/vision',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Vision[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchNonConformities = (params: FilterData) => {
  return new Promise<DefaultPaginated<NonConformity[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<NonConformity[]>>>(
        'api/receipt/nonConformity',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<NonConformity[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const UpdateNonConformity = (params: Cart) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    apiRfid
      .post<void, AxiosResponse<void>>('api/receipt/nonConformity', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchAreas = (params: FilterData) => {
  return new Promise<DefaultPaginated<Area[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Area[]>>>('api/receipt/area', {
        params
      })
      .then((response: AxiosResponse<DefaultPaginated<Area[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchDones = (params: FilterData) => {
  return new Promise<DefaultPaginated<NonConformity[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<NonConformity[]>>>(
        'api/receipt/dones',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<NonConformity[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchTransit = (params: FilterData) => {
  return new Promise<DefaultPaginated<NonConformity[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<NonConformity[]>>>(
        'api/receipt/transit',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<NonConformity[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchVisionsCount = (params: FilterData) => {
  return new Promise<DefaultPaginated<VisionCount[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<VisionCount[]>>>(
        'api/receipt/visionCount',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<VisionCount[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};
