import { create } from 'zustand';
import VisionCount from '~/@core/domain/model/rfidReports/VisionCount';
import TablePagination from '~/@core/domain/ui/TablePagination';
interface VisionCountState {
  visionsCount: VisionCount[];
  visionCountPagination: TablePagination;
  handleVisionCountPagination: (newPagination: TablePagination) => void;
  addVisionsCount: (visionsCountValues: VisionCount[]) => void;
}
const useVisionCountStore = create<VisionCountState>()(set => ({
  visionsCount: [],
  visionCountPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleVisionCountPagination: newPagination =>
    set({ visionCountPagination: newPagination }),
  addVisionsCount: visionsCountValues =>
    set({ visionsCount: visionsCountValues })
}));
export default useVisionCountStore;
