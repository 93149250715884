import { create } from 'zustand';
import Frequency from '~/@core/domain/model/rfidReports/Frequency';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface FrequencyState {
  frequencies: Frequency[];
  frequencyPagination: TablePagination;
  isLoadingFrequency: boolean;
  handleFrequencyPagination: (newPagination: TablePagination) => void;
  addFrequencies: (frequenciesValues: Frequency[]) => void;
  setIsLoadingFrequency: () => void;
}

const useFrequencyStore = create<FrequencyState>()(set => ({
  frequencies: [],
  frequencyPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingFrequency: false,
  handleFrequencyPagination: newPagination =>
    set({ frequencyPagination: newPagination }),
  addFrequencies: frequenciesValues => set({ frequencies: frequenciesValues }),
  setIsLoadingFrequency: () => {
    set(state => ({ isLoadingFrequency: !state.isLoadingFrequency }));
  }
}));

export default useFrequencyStore;
