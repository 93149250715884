import { create } from 'zustand';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface NonConformityStateGeral {
  nonConformities: ExpeditionDone[];
  nonConformityPagination: TablePagination;
  isLoadingNonConformities: boolean;
  handleNonConformityPagination: (newPagination: TablePagination) => void;
  addNonConformities: (nonConformitiesValues: ExpeditionDone[]) => void;
  setIsLoadingNonConformities: () => void;
}

const useNonConformityGeralStore = create<NonConformityStateGeral>()(set => ({
  nonConformities: [],
  nonConformityPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingNonConformities: false,
  handleNonConformityPagination: newPagination =>
    set({ nonConformityPagination: newPagination }),
  addNonConformities: nonConformityValues =>
    set({ nonConformities: nonConformityValues }),
  setIsLoadingNonConformities: () => {
    set(state => ({
      isLoadingNonConformities: !state.isLoadingNonConformities
    }));
  }
}));

export default useNonConformityGeralStore;
