import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, FormInstance, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GetProducers from '~/@core/application/useCases/rfidReports/inventory/getProducers';
import GetTransits from '~/@core/application/useCases/rfidReports/receipt/getTransits';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import FormFilterDataModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterDataModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useProducerStore from '~/zustand/rfidReports/inventory/producer-state';
import useTransitStore from '~/zustand/rfidReports/receipt/transit-state';
import { SearchButtonDiv } from '../../Inventory/Dones/FormFilterInventoryDone.styled';

const { Option } = Select;

interface Props {
  form: FormInstance<FormFilterDataModel>;
}
export default function FormFilterReceiptTransit(props: Props) {
  const { form } = props;
  const { t } = useTranslation();
  const {
    transitPagination,
    addTransits,
    handleTransitPagination,
    setIsLoadingTransit
  } = useTransitStore();
  const { producers, addProducers } = useProducerStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    form.setFieldsValue({
      dtaFilter: []
    });
  }, [form]);

  useEffect(() => {
    const getProducers = new GetProducers();

    handlePromiseUseCase<Producer[], AxiosError>(
      getProducers.execute(),
      producerOut => {
        addProducers(producerOut);
      },
      error => {
        activeAlert({
          message: JSON.stringify(
            error.response?.data
              ? error.response?.data
              : 'Houve um erro inesperado!'
          ),
          type: 'error',
          timeout: 5000
        });
      }
    );
  }, []);

  const onSubmitFilterReceiptTransit = useCallback(
    (formValues: FormFilterDataModel) => {
      const filterTransit = MapFormToFilter.toFilter(formValues, {
        pageSize: transitPagination.pageSize,
        pageNumber: transitPagination.current
      });

      const getTransits = new GetTransits();
      setIsLoadingTransit();

      handlePromiseUseCase<DefaultPaginated<NonConformity[]>, AxiosError>(
        getTransits.execute(filterTransit),
        transitsOut => {
          addTransits(transitsOut.results);
          handleTransitPagination({
            ...transitPagination,
            total: transitsOut.totalCount
          });
          setIsLoadingTransit();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingTransit();
        }
      );
    },
    [
      transitPagination,
      setIsLoadingTransit,
      handlePromiseUseCase,
      addTransits,
      handleTransitPagination,
      activeAlert
    ]
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSubmitFilterReceiptTransit}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item name="dtaFilter" label="Data de Consulta: ">
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Início', 'Fim']}
            />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            name="producerFilter"
            label="Sítio: "
            rules={[{ required: true }]}
          >
            <Select allowClear placeholder={t('select')}>
              {producers.map(producer => (
                <Option value={producer.account}>
                  {producer.account} - {producer.tradingName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {}}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
