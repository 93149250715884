import { create } from 'zustand';
import Vision from '~/@core/domain/model/rfidReports/Vision';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface VisionState {
  visions: Vision[];
  visionPagination: TablePagination;
  isLoadingVision: boolean;
  handleVisionPagination: (newPagination: TablePagination) => void;
  addVisions: (visionsValues: Vision[]) => void;
  setIsLoadingVision: () => void;
}

const useVisionStore = create<VisionState>()(set => ({
  visions: [],
  visionPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingVision: false,
  handleVisionPagination: newPagination =>
    set({ visionPagination: newPagination }),
  addVisions: visionsValues => set({ visions: visionsValues }),
  setIsLoadingVision: () => {
    set(state => ({ isLoadingVision: !state.isLoadingVision }));
  }
}));

export default useVisionStore;
