import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, FormInstance, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GetConsolidateds from '~/@core/application/useCases/rfidReports/inventory/getConsolidateds';
import GetProducers from '~/@core/application/useCases/rfidReports/inventory/getProducers';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import FormFilterTextModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterTextModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useConsolidatedStore from '~/zustand/rfidReports/inventory/consolidated-state';
import useProducerStore from '~/zustand/rfidReports/inventory/producer-state';
import { SearchButtonDiv } from '../Dones/FormFilterInventoryDone.styled';

const { Option } = Select;

interface Props {
  form: FormInstance<FormFilterTextModel>;
}
export default function FormFilterInventoryConsolidated(props: Props) {
  const { form } = props;
  const { t } = useTranslation();
  const {
    consolidatedPagination,
    addConsolidateds,
    handleConsolidatedPagination,
    setIsLoadingConsolidated
  } = useConsolidatedStore();

  const { producers, addProducers } = useProducerStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    const getProducers = new GetProducers();

    handlePromiseUseCase<Producer[], AxiosError>(
      getProducers.execute(),
      producerOut => {
        addProducers(producerOut);
      },
      error => {
        activeAlert({
          message: JSON.stringify(
            error.response?.data
              ? error.response?.data
              : 'Houve um erro inesperado!'
          ),
          type: 'error',
          timeout: 5000
        });
      }
    );
  }, []);

  const onSubmitConsolidated = useCallback(
    (formValues: FormFilterTextModel) => {
      const filterConsolidated = MapFormToFilter.toFilter(formValues, {
        pageSize: consolidatedPagination.pageSize,
        pageNumber: consolidatedPagination.current
      });

      const getConsolidateds = new GetConsolidateds();
      setIsLoadingConsolidated();

      handlePromiseUseCase<DefaultPaginated<Consolidated[]>, AxiosError>(
        getConsolidateds.execute(filterConsolidated),
        consolidatedsOut => {
          addConsolidateds(consolidatedsOut.results);
          handleConsolidatedPagination({
            ...consolidatedPagination,
            total: consolidatedsOut.totalCount
          });
          setIsLoadingConsolidated();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingConsolidated();
        }
      );
    },
    [
      consolidatedPagination,
      setIsLoadingConsolidated,
      handlePromiseUseCase,
      addConsolidateds,
      handleConsolidatedPagination,
      activeAlert
    ]
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSubmitConsolidated}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item
            name="txtFilter"
            label="Sitio: "
            rules={[{ required: true }]}
          >
            <Select allowClear placeholder={t('select')}>
              {producers.map(producer => (
                <Option value={producer.account}>
                  {producer.account} - {producer.tradingName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              id="consolidateds"
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {}}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
