import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import GetVisions from '~/@core/application/useCases/rfidReports/receipt/getVisions';
import GetVisionsCount from '~/@core/application/useCases/rfidReports/receipt/getVisionsCount';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import Vision from '~/@core/domain/model/rfidReports/Vision';
import VisionCount from '~/@core/domain/model/rfidReports/VisionCount';
import FormFilterDataModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterDataModel';
import DefaultRow from '~/components/bosons/DefaultRow';
import SearchButton from '~/components/Buttons/SearchButton';
import { globalContext } from '~/context/GlobalContext';
import useVisionStore from '~/zustand/rfidReports/receipt/vision-state';
import useVisionCountStore from '~/zustand/rfidReports/receipt/visionCount-state';
import { SearchButtonDiv } from '../../Inventory/Dones/FormFilterInventoryDone.styled';

interface Props {
  form: FormInstance<FormFilterDataModel>;
}
export default function FormFilterReceiptVision(props: Props) {
  const { form } = props;
  const {
    visionPagination,
    addVisions,
    handleVisionPagination,
    setIsLoadingVision
  } = useVisionStore();

  const { addVisionsCount } = useVisionCountStore();

  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  useEffect(() => {
    form.setFieldsValue({
      dtaFilter: []
    });
  }, [form]);

  const onSubmitFilterReceiptVision = useCallback(
    (formValues: FormFilterDataModel) => {
      const filterVision = MapFormToFilter.toFilter(formValues, {
        pageSize: visionPagination.pageSize,
        pageNumber: visionPagination.current
      });

      const getVisionsCount = new GetVisionsCount();
      setIsLoadingVision();
      handlePromiseUseCase<DefaultPaginated<VisionCount[]>, AxiosError>(
        getVisionsCount.execute(filterVision),
        visionsCountOut => {
          addVisionsCount(visionsCountOut.results);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      const getVisions = new GetVisions();

      handlePromiseUseCase<DefaultPaginated<Vision[]>, AxiosError>(
        getVisions.execute(filterVision),
        visionsOut => {
          addVisions(visionsOut.results);
          handleVisionPagination({
            ...visionPagination,
            total: visionsOut.totalCount
          });
          setIsLoadingVision();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
          setIsLoadingVision();
        }
      );
    },
    [
      visionPagination,
      setIsLoadingVision,
      handlePromiseUseCase,
      addVisionsCount,
      activeAlert,
      addVisions,
      handleVisionPagination
    ]
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSubmitFilterReceiptVision}>
      <DefaultRow>
        <Col lg={6}>
          <Form.Item name="dtaFilter" label="Data de Consulta: ">
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Início', 'Fim']}
            />
          </Form.Item>
        </Col>
        <Col lg={3}>
          <SearchButtonDiv>
            <SearchButton
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {}}
            />
          </SearchButtonDiv>
        </Col>
      </DefaultRow>
    </Form>
  );
}
