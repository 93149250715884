import { Button, FormInstance, Select, Table, Tooltip } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateNonConformity from '~/@core/application/useCases/rfidReports/receipt/updateNonConformity';
import Cart from '~/@core/domain/model/rfidReports/Cart';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import { globalContext } from '~/context/GlobalContext';
import { formatBr } from '~/utils/date';
import useNonConformityStore from '~/zustand/rfidReports/inventory/nonConformity-state';
import useAreaStore from '~/zustand/rfidReports/receipt/area-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableReceiptNonConformity(props: Props) {
  const { t } = useTranslation();
  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  const {
    addNonConformities,
    nonConformities,
    nonConformityPagination,
    handleNonConformityPagination,
    isLoadingNonConformity
  } = useNonConformityStore();
  const { form } = props;

  const { areas } = useAreaStore();
  const { Option } = Select;

  const toReceive = useCallback(
    (params: Cart) => {
      const useCase = new UpdateNonConformity();

      handlePromiseUseCase<AxiosResponse<void>, AxiosError>(
        useCase.execute(params),
        () => {
          activeAlert({
            message: 'Status atualizado com sucesso',
            type: 'success',
            timeout: 5000
          });
          form.submit();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, form, handlePromiseUseCase]
  );

  const changeArea = useCallback(
    (
      valueParam: number,
      property: string,
      indexTable: number,
      areaName: string
    ) => {
      let value: string | number = 0;
      if (typeof valueParam === 'string') {
        value = valueParam;
      }
      if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
        value = valueParam;
      }
      const newConsolidated = nonConformities.map(newCart => {
        return {
          ...newCart,
          [property]: newCart.carts.map((cart: any) => {
            if (cart.id === areaName) {
              return {
                ...cart,
                area: value
              };
            }
            return {
              ...cart
            };
          }),
          changed: true
        };
      });
      addNonConformities(newConsolidated);
    },
    [nonConformities, addNonConformities]
  );

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Recebimentos com Inconformidades
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={nonConformities}
          loading={isLoadingNonConformity}
          pagination={{
            pageSize: nonConformityPagination.pageSize,
            current: nonConformityPagination.current,
            showSizeChanger: true,
            total: nonConformityPagination.total,
            onChange(page, pageSize) {
              handleNonConformityPagination({
                ...nonConformityPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: receiptNonConformities => (
              <Table
                dataSource={receiptNonConformities.carts}
                expandable={{
                  expandedRowRender: receiptNonConformitiesCarts => (
                    <Table
                      dataSource={receiptNonConformitiesCarts.acessories}
                      bordered
                    >
                      <Table.Column
                        dataIndex="productCode"
                        title="Codigo Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />
                      <Table.Column
                        dataIndex="results"
                        title="Resultado/Esperado"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column
                  dataIndex="status"
                  render={(_, cart: Cart) =>
                    cart.status !== 3 ? (
                      <Tooltip>
                        <StatusCircle color="#FA541C" />
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <StatusCircle color="#52C41A" />
                      </Tooltip>
                    )
                  }
                  title="Status"
                />
                <Table.Column
                  dataIndex="productCode"
                  title="Serial do Carrinho"
                />
                <Table.Column
                  dataIndex="area"
                  title="Área"
                  render={(_, cart: Cart, index: number) =>
                    cart.status !== 3 ? (
                      <Select
                        allowClear
                        placeholder={t('select')}
                        onSelect={value => {
                          changeArea(value ?? '', 'carts', index, cart.id);
                        }}
                      >
                        {areas.map(area => (
                          <Option value={area.id}>{area.name}</Option>
                        ))}
                      </Select>
                    ) : (
                      <p>{cart.area}</p>
                    )
                  }
                />

                <Table.Column dataIndex="numberMC" title="TotalMC" />
                <Table.Column
                  dataIndex="action"
                  title="Ação"
                  render={(_, cart: Cart) =>
                    cart.status !== 3 ? (
                      <Button
                        style={{
                          border: 'none',
                          backgroundColor: '#F78E1E',
                          color: '#ffffff'
                        }}
                        htmlType="submit"
                        onClick={() => toReceive(cart)}
                      >
                        Receber
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          border: 'none',
                          backgroundColor: 'gray',
                          color: '#ffffff'
                        }}
                      >
                        Recebido
                      </Button>
                    )
                  }
                />
              </Table>
            )
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, receiptNonConformities: NonConformity) =>
              receiptNonConformities.status !== 2 ? (
                <Tooltip>
                  <StatusCircle color="#FA541C" />
                </Tooltip>
              ) : (
                <Tooltip>
                  <StatusCircle color="#52C41A" />
                </Tooltip>
              )
            }
            title="Status"
          />

          <Table.Column
            dataIndex="sendDate"
            title="Data Envio"
            render={(sendDate: string) => moment(sendDate).format(formatBr)}
          />
          <Table.Column
            dataIndex="receiveDate"
            title="Data Recebimento"
            render={(receiveDate: string) =>
              moment(receiveDate).format(formatBr)
            }
          />
          <Table.Column dataIndex="document" title="Romaneio" />

          <Table.Column
            dataIndex="numberCart"
            title="Carrinho Resultado/Esperado"
          />
          <Table.Column dataIndex="numberMC" title="MC Resultado/Esperado" />
          <Table.Column dataIndex="nonConformity" title="Inconformidade" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}
