import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ICancelGfp from '~/@core/domain/filters/gfp/FilterCancelGfp';
import FilterCreateGfp from '~/@core/domain/filters/gfp/FilterCreateGfp';
import FilterGetGfpPrint from '~/@core/domain/filters/gfp/FilterGetGfpPrint';
import FilterGfpCarts from '~/@core/domain/filters/gfp/FilterGfpCarts';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import IGfp from '~/@core/domain/model/gfp/IGfp';
import { apiGfp } from '../config/index';

export const createGfp = (params: FilterCreateGfp) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    apiGfp
      .post<void, AxiosResponse<void>>('/gfp', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchGfpCarts = (params: FilterGfpCarts) => {
  return new Promise<DefaultPaginated<GfpCarts[]>>((resolve, reject) => {
    apiGfp
      .get<void, AxiosResponse<DefaultPaginated<GfpCarts[]>>>('/gfp/carts', {
        params
      })
      .then((response: AxiosResponse<DefaultPaginated<GfpCarts[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchGfpPrint = (queryParams: FilterGetGfpPrint) => {
  return new Promise<IGfp[]>((resolve, reject) => {
    apiGfp
      .get<void, AxiosResponse<IGfp[]>>('/gfp/print', {
        params: queryParams,
        paramsSerializer: params => {
          return params.gfpIds
            .map((id: number) => `gfpIds=${id}&isTagCart=${params.isTagCart}`)
            .join('&');
        }
      })
      .then((response: AxiosResponse<IGfp[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const cancelGfp = (params: ICancelGfp) => {
  return new Promise<number[]>((resolve, reject) => {
    apiGfp
      .delete<void, AxiosResponse<number[]>>('/gfp/cancel', {
        data: params
      })
      .then((response: AxiosResponse<number[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};
