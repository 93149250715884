import { create } from 'zustand';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface TransitState {
  transits: ExpeditionDone[];
  transitPagination: TablePagination;
  isLoadingTransits: boolean;
  handleTransitPagination: (newPagination: TablePagination) => void;
  addTransits: (transitsValues: ExpeditionDone[]) => void;
  setIsLoadingTransits: () => void;
}

const useTransitStore = create<TransitState>()(set => ({
  transits: [],
  transitPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  isLoadingTransits: false,
  handleTransitPagination: newPagination =>
    set({ transitPagination: newPagination }),
  addTransits: transitsValues => set({ transits: transitsValues }),
  setIsLoadingTransits: () => {
    set(state => ({
      isLoadingTransits: !state.isLoadingTransits
    }));
  }
}));

export default useTransitStore;
